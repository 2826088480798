import { reactive, type Ref } from "vue";
import type { Events, State, Payload } from "./types";

import auth from "@/store/auth";
import { createEvents, getEvents, updateEvents } from "@/api/events.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  events: {
    id: "",
    target_url: "",
    subscriptions: [],
    enabled: false,
    reachable: false,
  },
});

// methods
const methods = {
  async list(): Promise<void> {
    await getEvents().then((response: AxiosResponse<Events>) => {
      state.events = response.data;
    });
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData, true);
    createEvents(payload).then(() => {
      this.list();
    });
  },
  update(id: string, formData: Ref, enabled: boolean): void {
    const payload: Payload = this.getCreatePayload(formData, enabled);
    updateEvents(id, payload).then(() => {
      this.list();
    });
  },
  getCreatePayload(formData: Ref, enabled: boolean): Payload {
    const payload: Payload = {
      target_url: formData.value.targetUrl.value,
      enabled: enabled,
      subscriptions: formData.value.subscriptions.value,
      updated_by: auth.state.atlassianUsername,
    };
    return payload;
  },
  toggleEventStatus(events: Events): void {
    const payload: Payload = {
      enabled: events.enabled,
      target_url: events.target_url,
      subscriptions: events.subscriptions,
    };
    updateEvents(events.id, payload).then(() => {
      this.list();
    });
  },
};

export default {
  state,
  methods,
};
