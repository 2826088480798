import router from './router';
import pinia from './pinia';
import { Toast, settings } from './vue-toastification';

type PluginWithOptionalOptions<TOptions = Record<string, any>> = {
  lib: any;
  options?: TOptions;
};

export default <PluginWithOptionalOptions[]>[
  { lib: pinia},
  { lib: router},
  { lib: Toast, settings}
];

export { router };
