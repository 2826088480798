import type { SettingsPayload } from "@/store/auth/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/settings";

// List
const getSettings = (): Promise<AxiosResponse> => {
  return apiClient.get(END_POINT);
};

// Update
const updateSettings = (
  id: string,
  payload: SettingsPayload
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.put(`${END_POINT}/${id}`, payload, config);
};

export { getSettings, updateSettings };
