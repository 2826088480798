import axios, { type AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import type { AccountIdQueryParams, QueryParams } from "@/store/auth/types";
import type { LocationQuery } from "vue-router";

const authPath = `${import.meta.env.VITE_APP_URL}/auth/getting-started`;
const authPathAssetPanel = `${import.meta.env.VITE_APP_URL}/auth/asset-panel`;

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
});

const getAuth = (
  queryParams: LocationQuery | QueryParams | AccountIdQueryParams
): Promise<AxiosResponse> => axios.get(authPath, { params: queryParams });

const getAuthAssetPanel = (
  queryParams: LocationQuery | QueryParams | AccountIdQueryParams
): Promise<AxiosResponse> =>
  axios.get(authPathAssetPanel, { params: queryParams });

export { getAuth, getAuthAssetPanel };
