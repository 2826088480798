import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';

enum OPTIONS_TYPES {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DROPDOWN = 'dropdown',
}


/* NOTE - Improvement candidate
 * The prop 'required' can be update in the future to receive more
 * customizable options to render a pin component. In case that
 * happen, BaseDropdown should be promoted to ComposedDropdown.
 */
interface Props {
  fieldType?: OPTIONS_TYPES;
  id: number;
  label: string;
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps> | string;
  required?: boolean;
  isDisabled?: boolean;
}

export { type Props, OPTIONS_TYPES };