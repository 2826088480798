import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/reports',
    name: RouteName.HOME,
    component: () => import('./views/HomeView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/reports/new',
    name: RouteName.NEW,
    component: () => import('./views/CreateView'),
    meta: { atlaskit: true, appWrapper: true },
  },
  {
    path: '/reports/:id/edit',
    name: RouteName.EDIT,
    component: () => import('./views/EditView'),
    meta: { atlaskit: true, appWrapper: true },
    props: true,
  },
];
