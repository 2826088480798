<script setup lang="ts">
import { computed } from 'vue';
import type { Prop } from './types';
import { FONTS, SIZES, TAGS, COLORS, WEIGHT } from './types';

const props = withDefaults(defineProps<Prop>(), {
  color: COLORS.BLUE_500,
  font: FONTS.SANS,
  size: SIZES.H500_SUB_SECTION_HEADING,
  tag: TAGS.P,
  weight: WEIGHT.h500,
});

const classes = computed(() => {
  const { color, font, size, weight } = props;

  return [color, font, size, weight ?? WEIGHT[size]];
});
</script>

<template>
  <component
    :is="tag"
    :class="classes"
  >
    <slot />
  </component>
</template>

<style scoped>
.h100 {
  /* Lowest level heading */
  @apply text-[11px] leading-4 tracking-normal;
}

.h200 {
  /* Low level headings */
  @apply text-xs leading-4 tracking-normal;
}

.h300 {
  /* Heading up a group of list items */
  @apply text-xs uppercase leading-4 tracking-normal;
}

.h400 {
  /* Deep heading and for highlighting important pieces of information */
  @apply text-sm leading-4 tracking-[0.003em];
}

.h500 {
  /* Sub-section and field group headings */
  @apply text-base leading-5 tracking-[0.006em];
}

.h600 {
  /* Heading that identify key functionality */
  @apply text-xl leading-6 tracking-[0.008em];
}

.h700 {
  /* Main titles, use only per page */
  @apply text-2xl leading-7 tracking-[0.01em];
}

.h800 {
  /* Empty state and feature introductions. Top level headers */
  @apply text-[29px] leading-8 tracking-[0.01em];
}

.h900 {
  /* Oversized screen titles. Use in moderation */
  @apply text-[35px] leading-10 tracking-[0.01em];
}
</style>
