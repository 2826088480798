import type { App } from 'vue';
import Component from './AppIcon.vue';
import { AppIconProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppIcon', Component);
    app.config.globalProperties.$AppIconProps = AppIconProps;
  },
};
