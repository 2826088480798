export enum VARIATIONS {
  UPLOAD,
  MAP,
}

export interface Prop {
  mapState?: {
    columns: number;
    rows: number;
    mapped: number;
  },
  variation: VARIATIONS
}

export const AssetImportHeaderProps = {
  VARIATIONS
};