<script setup lang="ts">
import ErrorIcon from '@atlaskit/icon/glyph/error';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  size?: ComponentProps<typeof ErrorIcon>['size'];
  primaryColor?: ComponentProps<typeof ErrorIcon>['primaryColor'];
  secondaryColor?: ComponentProps<typeof ErrorIcon>['secondaryColor'];
  label?: ComponentProps<typeof ErrorIcon>['label'];
}>();

const AtlasErrorIcon = applyPureReactInVue(ErrorIcon);
</script>

<template>
  <AtlasErrorIcon
    :size="size"
    :primaryColor="primaryColor"
    :secondaryColor="secondaryColor"
    :label="label"
  />
</template>
