<script setup lang="ts">
import AtlasBenefits from '#atlaskit/components/AtlasBenefits.vue';
import { ref } from 'vue';

const KEY = 'amfj:asset-panel-dismissied';

const notDismissed = ref(localStorage.getItem(KEY) !== 'true');

const dismiss = () => {
  localStorage.setItem(KEY, 'true');
  notDismissed.value = false;
};
</script>

<template>
  <AtlasBenefits
    v-model="notDismissed"
    image="/assets-panel-onboarding.png"
    heading="Introducing our new asset linking experience"
    :actions="[
      {
        text: 'Learn more',
        href: 'https://updates.assetmanagementforjira.com/announcements/introducing-the-new-amfj-asset-panel/',
        target: '_blank',
      },
      {
        text: 'Dismiss',
        onClick: dismiss,
      },
    ]"
  >
    <p>
      Check out our new asset panel that will replace the Linked Assets field in Jira. Your existing linked
      assets have already been migrated to this new panel.
    </p>
  </AtlasBenefits>
</template>
