import type {
  CheckinPayload,
  CheckoutPayload,
  CommentPayload,
  ListParams,
  Payload,
} from "@/store/licenses/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/licenses";

// List Licenses
const getLicenses = (params: ListParams): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: params,
    headers: { isBlocking: true },
  };
  return apiClient.get(END_POINT, config);
};

// Create License
const postLicenses = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Get License
const getLicense = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(`${END_POINT}/${id}`, config);
};

const getLicenseComments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/comments`);
};

const createComment = (
  id: string,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${id}/comments`, payload);
};

const updateComment = (
  license_id: number,
  id: number,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${license_id}/comments/${id}`, payload);
};

const deleteComment = (
  license_id: number,
  id: number
): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${license_id}/comments/${id}`);
};

const getLicenseHistory = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/history`);
};

const deleteLicense = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

const updateLicense = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

const checkoutLicense = (
  id: string,
  payload: CheckoutPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${id}/checkout`, payload);
};

//TODO: Need to clean code this
const checkinLicense = (
  checkoutId: string,
  payload: CheckinPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${checkoutId}/checkin`, payload);
};

// license attachments
const getLicenseAttachments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/attachments`);
};

const deleteLicenseAttachments = (
  id: string,
  licenseId: string
): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${licenseId}/attachments/${id}`);
};

export {
  getLicenses,
  postLicenses,
  getLicense,
  getLicenseComments,
  getLicenseHistory,
  deleteLicense,
  updateLicense,
  checkoutLicense,
  createComment,
  updateComment,
  deleteComment,
  checkinLicense,
  getLicenseAttachments,
  deleteLicenseAttachments,
};
