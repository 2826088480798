<script setup lang="ts">
import { computed } from 'vue';
import { APPEARANCES } from '@Types';
import type { Prop } from './types';
import { SPACINGS } from './types';
import AppIcon from '../../app/AppIcon/AppIcon.vue';
import AppLoader from '../../app/AppLoader/AppLoader.vue';

const props = withDefaults(defineProps<Prop>(), {
  spacing: SPACINGS.DEFAULT,
  variation: APPEARANCES.DEFAULT,
});

const cssClasses = computed(() => [
  'button',
  `button-spacing-${props.spacing}`,
  props.variation,
  props.disabled ? 'is-disabled' : '',
  props.selected ? 'is-selected' : '',
  props.loading ? 'is-loading' : '',
  props.wide ? 'w-full' : '',
]);
</script>

<template>
  <RouterLink
    v-if="url && label"
    :class="cssClasses"
    :to="url"
  >
    <span
      v-if="loading"
      class="button-loader"
    >
      <AppLoader :variation="variation" />
    </span>

    <span class="content">
      <AppIcon
        v-if="iconLeft"
        :icon="iconLeft"
      />

      {{ label }}

      <AppIcon
        v-if="iconRight"
        :icon="iconRight"
      />
    </span>
  </RouterLink>
</template>

<style scoped>
@import url('../../app/AppButton/style.css');
</style>
