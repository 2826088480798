import { fetcher } from '#common';
import type { DetailsResponse, CurrentUser } from '../types';

export const api = {
  authenticate: () => {
    return fetcher.get('/auth/getting-started');
  },
  getAuth: async () => {
    const { AP } = window;
    if (typeof AP === 'undefined' || typeof AP.request !== 'function') {
      return;
    }

    const data = await AP.request('/rest/api/3/myself?expand=groups');
    return JSON.parse(data.body) as CurrentUser;
  },
  getDetails: () => {
    return fetcher.get<DetailsResponse>('details');
  },
};
