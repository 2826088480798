import type { AxiosResponse } from 'axios';
import apiClient from './client';

// List
const getJiraProjects = (): Promise<AxiosResponse> => {
  const jiraProjectsEndPoint = '/jira-projects';
  return apiClient.get(jiraProjectsEndPoint);
};

export { getJiraProjects };
