import type { AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/details";

// Get app details
const getDetails = (): Promise<AxiosResponse> => {
  return apiClient.get(END_POINT);
};

// Post install event
const postInstall = (): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}`, {});
};

export { getDetails, postInstall };
