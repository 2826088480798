import type { Payload } from "@/store/customfields/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/custom-fields";

// List
const getCustomFields = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(END_POINT, config);
};

// Create Custom Field
const postCustomFields = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Custom Field
const updateCustomField = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Delete Custom Field
const deleteCustomField = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

// Sync existing Issues
const postSyncIssues = (): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/update-issues`, {});
};

export {
  getCustomFields,
  postCustomFields,
  updateCustomField,
  deleteCustomField,
  postSyncIssues,
};
