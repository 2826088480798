export enum RouteName {
  HOME = 'licenses-home',
  CREATE = 'licenses-new',
  EDIT = 'licenses-edit',
  FIELDS = 'licenses-fields',
}

export enum QueryKey {
  LICENSES = 'licenses',
  LICENSE = 'license',
  FIELDS = 'licenses-fields',
  FIELD = 'licenses-field',
}
