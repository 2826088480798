import { reactive } from "vue";
import type { Log, State } from "./types";

import { getAuditLogs } from "@/api/auditlogs.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  logs: [],
  tableColumns: [
    { key: "actor", label: "User", sortable: true },
    { key: "created_at", label: "Date", sortable: true },
    { key: "action", label: "Action", sortable: true },
    { key: "entity", label: "Activity", sortable: false },
  ],
});

// methods
const methods = {
  async list(): Promise<void> {
    await getAuditLogs().then((response: AxiosResponse<Log[]>) => {
      state.logs = response.data;
    });
  },
};

export default {
  state,
  methods,
};
