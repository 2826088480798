import type { Payload } from "@/store/locations/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/locations";

// List
const getLocations = (isBlocking: boolean): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking } };
  return apiClient.get(END_POINT, config);
};

// Create Location
const postLocations = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Location
const updateLocation = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Get Location
const getLocation = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}`);
};

// Delete location
const deleteLocation = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

export {
  getLocations,
  postLocations,
  updateLocation,
  getLocation,
  deleteLocation,
};
