import { dateFormats } from '@Constants';
import { DATE_PATTERNS } from '@Types';

function isValidDate(input: string): boolean | Date  {
  let result: boolean | Date = false;

  for (const format of dateFormats) {
    const match = input.match(format.pattern);

    if (match) {
      const dateParts = match.slice(1).map(Number);
      const date = format.constructor(dateParts);

      const isValidDate = date && !isNaN(date.getTime());

      if (isValidDate) result = date;

      break;
    }
  }

  return result;
}

function isValidDateFormat(dateString: string, expectedFormat: DATE_PATTERNS): null | Date {
  let result: null | Date = null;
  const dateFormat = dateFormats.filter(({format}) => format === expectedFormat);

  if (!dateFormat.length) {
    return null;
  }

  const { constructor, pattern } = dateFormat[0];
  const match = dateString.match(pattern);

  if (match) {
    const dateParts = match.slice(1).map(Number);
    const date = constructor(dateParts);

    const isValidDate = date && !isNaN(date.getTime());

    if (isValidDate) result = date;
  }

  return result;
}

export { isValidDate, isValidDateFormat };