import { reactive, type Ref } from "vue";
import type { Location, Payload, State } from "./types";

import auth from "@/store/auth";
import {
  deleteLocation,
  getLocation,
  getLocations,
  postLocations,
  updateLocation,
} from "@/api/locations.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  locations: [],
  currentLocation: {
    id: "",
    name: "",
    asset_count: 0,
  },
  tableColumns: [
    { key: "name", label: "Name", sortable: true },
    { key: "asset_count", label: "Number of Assets", sortable: true },
    { key: "update", label: "", sortable: false },
  ],
  didCreateFirstLocation: false,
});

// methods
const methods = {
  async list(isBlocking: boolean): Promise<void> {
    await getLocations(isBlocking).then(
      (response: AxiosResponse<Location[]>) => {
        state.locations = response.data;
      }
    );
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    if (state.locations.length === 0) {
      state.didCreateFirstLocation = true;
    }
    postLocations(payload).then(() => {
      this.list(true);
    });
  },
  update(id: string, formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    updateLocation(id, payload).then((response: AxiosResponse<Location>) => {
      state.currentLocation = response.data;
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      updated_by: auth.state.atlassianUsername,
    };
    return payload;
  },
  get(id: string): void {
    getLocation(id).then((response: AxiosResponse<Location>) => {
      state.currentLocation = response.data;
    });
  },
  setCurrentLocation(id: string): void {
    state.locations.forEach((loc: Location) => {
      if (loc.id === id) {
        state.currentLocation = loc;
      }
    });
  },
  delete(id: string): void {
    deleteLocation(id).then(() => {
      state.currentLocation = {
        id: "",
        name: "",
        asset_count: 0,
      };
    });
  },
  updateDidCreateFirstLocation(): void {
    state.didCreateFirstLocation = false;
  },
};

export default {
  state,
  methods,
};
