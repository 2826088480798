<template>
  <div class="text-center mx-auto my-20">
    <h3 class="mt-2 text-lg font-medium text-gray-900">
      You've found a glitch!
    </h3>
    <p class="text-gray-500">
      Asset Management for Jira can only be run within Jira. Accessing the
      application from outside Jira is not allowed.
      <br />
      Please contact us at
      <strong>support@assetmanagementforjira.com</strong> for help.
    </p>
  </div>
</template>
