<script setup lang="ts">
import AppProvider from '@atlaskit/app-provider';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  defaultColorMode?: ComponentProps<typeof AppProvider>['defaultColorMode'];
  defaultTheme?: ComponentProps<typeof AppProvider>['defaultTheme'];
}>();

const AtlasAppProvider = applyPureReactInVue(AppProvider);
</script>

<template>
  <AtlasAppProvider
    :defaultColorMode="defaultColorMode"
    :defaultTheme="defaultTheme"
  >
    <slot />
  </AtlasAppProvider>
</template>
