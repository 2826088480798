<template>
  <AppText
    :color="$AppTextProps.COLORS.SLATE_600"
    :size="$AppTextProps.SIZES.H500_SUB_SECTION_HEADING"
    :tag="$AppTextProps.TAGS.H2"
  >
    Match your data to our predefined data fields or create your own fields. If you don't
    select a match for a column, this column will not be imported.
    <b class="highlight">"Asset Name"</b> and <b class="highlight">"Asset type"</b> are
    required and must be matched to your data.
  </AppText>
</template>

<style scoped>
.highlight {
  @apply font-sans font-semibold text-slate-800;
}
</style>
