<template>
  <div
    class="max-w-7xl min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
  >
    <div class="mx-auto max-w-max">
      <main class="sm:flex">
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1
              class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl"
            >
              Your license has expired.
            </h1>
            <p class="mt-1 text-base text-gray-500">
              Please renew your license to continue accessing Asset Management for Jira.
            </p>
            <p class="mt-1 text-sm text-gray-500">
              Contact us at <strong>support@assetmanagementforjira.com</strong> for
      help.
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
