import type { App } from 'vue';
import Component from './AppLoader.vue';
import { AppLoaderProps } from './types';

export default {
  install: (app: App) => {
    app.component('AppLoader', Component);
    app.config.globalProperties.$AppLoaderProps = AppLoaderProps;
  },
};
