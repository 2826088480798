import { OPTIONS_TYPES, type OptionItem } from '@BaseUi';

const requiredOptions: OptionItem[] = [
  {
    fieldType: OPTIONS_TYPES.TEXT,
    icon: 'text.svg',
    id: 1,
    isDisabled: false,
    label: 'Asset Name',
    required: true,
  },
  {
    fieldType: OPTIONS_TYPES.TEXT,
    icon: 'text.svg',
    id: 2,
    isDisabled: false,
    label: 'Asset Type',
    required: true,
  },
];

const topLevelOptions: OptionItem[] = [
  {
    fieldType: OPTIONS_TYPES.TEXT,
    icon: 'text.svg',
    id: 3,
    isDisabled: true,
    label: 'Location',
    required: false,
  },
  {
    fieldType: OPTIONS_TYPES.TEXT,
    icon: 'text.svg',
    id: 4,
    isDisabled: true,
    label: 'Loanee',
    required: false,
  },
];

const requiredColumnsNames = ['Asset Name', 'Name', 'Asset Type', 'Type'];

const topLevelColumnNames = ['Location', 'Loanee'];

export {
  requiredOptions,
  requiredColumnsNames,
  topLevelColumnNames,
  topLevelOptions
};