import { TEXT_COLORS as COLORS } from '@Types';

enum TAGS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',  
  P = 'p',
  SPAN = 'span',
}

enum FONTS {
  SANS = 'sans',
  MONO = 'mono',
}

enum SIZES {
  H100_LOWEST_HEADING = 'h100',
  H200_LOW_HEADING = 'h200',
  H300_GROUP_LIST_HEADING = 'h300',
  H400_DEEP_HEADING_IMPORTANT = 'h400',
  H500_SUB_SECTION_HEADING = 'h500',
  H600_KEY_FEATURE_HEADING = 'h600',
  H700_MAIN_TITLE = 'h700',
  H800_TOP_LEVEL_HEADING = 'h800',
  H900_OVERSIZED = 'h900',
}

interface Prop {
  color?: COLORS | string;
  font?: FONTS;
  size?: SIZES;
  tag?: TAGS;
  weight?: string;
}

enum WEIGHT {
  h100 = 'font-bold',
  h200 = 'font-semibold',
  h300 = 'font-semibold',
  h400 = 'font-semibold',
  h500 = 'font-medium',
  h600 = 'font-medium',
  h700 = 'font-medium',
  h800 = 'font-semibold',
  h900 = 'font-medium',
}

const AppTextProps = {
  COLORS,
  FONTS,
  SIZES,
  TAGS,
  WEIGHT,
};

export { 
  AppTextProps,
  COLORS,
  FONTS,
  SIZES,
  TAGS,
  WEIGHT,
  // type Prop, // TODO :: review after vite migration [AMFJ-46]
  Prop,
};
