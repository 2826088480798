import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default {
  formatDate(dt: string | null): string | null {
    if (dt === "" || dt === null) {
      return null;
    }
    return dayjs(dt).toISOString();
  },
  renderDate(dt: string | null): string {
    if (dt === "" || dt === null) {
      return "";
    }
    const dtFormat = "MMMM Do YYYY, h:mm a";
    return dayjs.utc(dt).local().format(dtFormat);
  },
  renderToDate(dt: string | null): string {
    if (dt === "" || dt === null) {
      return "";
    }
    const dtFormat = "MMMM Do YYYY";
    return dayjs(dt).format(dtFormat);
  },
  renderDateWithoutTZ(dt: string | null): string {
    if (dt === "" || dt === null) {
      return "";
    }
    const dtFormat = "YYYY-MM-DD";
    return dayjs(dt).format(dtFormat);
  },
  renderToDtString(dt: string | null): string | null {
    if (dt === "" || dt === null) {
      return null;
    }
    return dayjs(dt).toISOString();
  },
  renderStringToDt(dt: string | undefined): string | undefined {
    if (dt === "" || dt === undefined) {
      return dt;
    }

    return dayjs(dt).format("YYYY-MM-DD");
  },
  renderStringFormatToDt(
    dt: string | null,
    format: string | undefined
  ): string | null {
    if (dt === "" || dt === null) {
      return null;
    }
    return dayjs(dt, format).format("YYYY-MM-DD");
  },
  timeDifference(dt: string): string {
    const localDate = dayjs.utc(dt).local();
    return localDate.from(dayjs());
  },
  supportsLocalStorage(): boolean {
    try {
      return "localStorage" in window && window.localStorage !== null;
    } catch (e) {
      return false;
    }
  },
  extractHostname(url: string) {
    let hostname;
    // eslint-disable-next-line
    let rest;

    if (url.indexOf("//") > -1) {
      [, , hostname, ...rest] = url.split("/");
    } else {
      [hostname, ...rest] = url.split("/");
    }

    [hostname, ...rest] = hostname.split(":");
    [hostname, ...rest] = hostname.split("?");
    return hostname;
  },
  extractRootDomain(url: string) {
    let domain = this.extractHostname(url);
    const splitArr = domain.split(".");
    const arrLen = splitArr.length;

    if (arrLen > 2) {
      domain = `${splitArr[arrLen - 2]}.${splitArr[arrLen - 1]}`;
      if (
        splitArr[arrLen - 2].length === 2 &&
        splitArr[arrLen - 1].length === 2
      ) {
        domain = `${splitArr[arrLen - 3]}.${domain}`;
      }
    }
    return domain;
  },
  shouldAllowRootDomain() {
    // try {
    //   // @ts-expect-error This comes from the Atlassian connect library
    //   const origin = AP._data.origin;
    //   const rootDomain = this.extractRootDomain(origin);
    //   if (rootDomain === "atlassian.net") {
    //     return true;
    //   }
    // } catch (e) {
    //   return false;
    // }
    // return false;
    return true;
  },
  getHostname() {
    try {
      // @ts-expect-error This comes from the Atlassian connect library
      const origin = AP._data.origin;
      return this.extractHostname(origin);
    } catch (e) {
      return "";
    }
  },
  getAtlassianBaseURL() {
    try {
      // @ts-expect-error This comes from the Atlassian connect library
      const origin = AP._data.origin;
      const hostname = this.extractHostname(origin);
      const conelikePkg = `${import.meta.env.VITE_APP_AMFJ_PKG}`;
      return `https://${hostname}/plugins/servlet/ac/${conelikePkg}/asset-mgmt-home`;
    } catch (e) {
      return "/";
    }
  },
  getAtlassianComponentURL(componentPath: string) {
    // @ts-expect-error This comes from the Atlassian connect library
    if (AP) {
      const componentURL = `${this.getAtlassianBaseURL()}#!${componentPath}`;
      return componentURL;
    }

    return componentPath;
  },
  getFileExtension(filename: string) {
    return filename.split(".").pop();
  },
  compareArrays(a: string[] | undefined, b: string[] | undefined): boolean {
    if (a === undefined || b === undefined) return false;

    if (a.length !== b.length) return false;
    else {
      // Comparing each element of your array
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
  },
};
