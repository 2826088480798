import {
  getUsers,
  getUserById,
  getUserByName,
  postUser,
  updateUser,
  deleteUser,
  postSyncUsers,
} from "@/api/users.api";
import type { State, Payload, User } from "./types";

import { reactive, type Ref } from "vue";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  users: [],
  tableColumns: [
    { key: "name", label: "Name", sortable: true },
    { key: "update", label: "", sortable: false },
  ],
  currentUser: {
    id: "",
    avatar_url: "",
    name: "",
    accessories: [],
    assets: [],
    licenses: [],
  },
  userSync: {
    created: 0,
    deleted: 0,
  },
  shouldShowSyncUsersModal: false,
});

// methods
const methods = {
  list(isBlocking = false): void {
    getUsers(isBlocking).then((response: AxiosResponse) => {
      state.users = response.data.db_users;
    });
  },

  get(id: string): void {
    getUserById(id).then((response: AxiosResponse<User>) => {
      state.currentUser = response.data;
    });
  },
  async getByName(name: string): Promise<void> {
    await getUserByName(name).then((response: AxiosResponse<User>) => {
      state.currentUser = response.data;
    });
  },
  async create(formData: Ref): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    await postUser(payload).then((response: AxiosResponse<User>) => {
      state.currentUser = response.data;
    });
  },
  async update(id: string, formData: Ref): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    updateUser(id, payload).then((response: AxiosResponse<User>) => {
      state.currentUser = response.data;
    });
  },
  delete(id: string): void {
    deleteUser(id).then((response) => {
      state.currentUser = {
        id: "",
        avatar_url: "",
        name: "",
        accessories: [],
        assets: [],
        licenses: [],
      };
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
    };
    return payload;
  },
  syncUsers(): void {
    postSyncUsers().then((response) => {
      state.userSync = response.data;
      state.shouldShowSyncUsersModal = true;
      this.list();
    });
  },
  shouldShowSyncUsersModal(val: boolean): void {
    state.shouldShowSyncUsersModal = val;
  },
};

export default {
  state,
  methods,
};
