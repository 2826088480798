import {
  AppButtonPlugin,
  AppIconPlugin,
  AppLoaderPlugin,
  AppTextPlugin
} from '@AppUi';

export default [
  AppButtonPlugin,
  AppIconPlugin,
  AppLoaderPlugin,
  AppTextPlugin
];