import Toast, { type PluginOptions } from "vue-toastification";

const settings: PluginOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 8,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      return false;
    }
    
    return toast;
  }
};

export {
  Toast,
  settings
};