import { reactive, type Ref } from "vue";
import type { KandjiSettings, Payload, State } from "./types";

import {
  getKandjiSettings,
  updateKandjiSetting,
  createKandjiSetting,
} from "@/api/kandji.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  kandjiSettings: {
    id: "",
    api_url: "",
    api_token: "",
    default_asset_type_id: "",
    default_location_id: "",
    auto_assign_to_user: false,
    kandji_fields_mapping: {},
    auto_create_asset: false,
    last_sync: null,
    sync_report: {},
  },
});

// methods
const methods = {
  async list(): Promise<void> {
    await getKandjiSettings().then(
      (response: AxiosResponse<KandjiSettings>) => {
        state.kandjiSettings = response.data;
      }
    );
  },
  getCreatePayload(formData: Ref, kandjiFieldsMapping: Ref): Payload {
    const kandjiFieldsPayload: any = {};
    Object.keys(kandjiFieldsMapping.value).forEach((key) => {
      kandjiFieldsMapping.value[key].forEach((field: any) => {
        if (field.value) {
          kandjiFieldsPayload[field.id] = field.value.value;
        }
      });
    });

    const payload: Payload = {
      api_url: formData.value.apiUrl.value,
      api_token: formData.value.apiToken.value,
      default_asset_type_id: formData.value.defaultAssetType.value,
      auto_assign_to_user: formData.value.autoAssignAssets.value.value,
      kandji_fields_mapping: JSON.stringify(kandjiFieldsPayload),
      auto_create_asset: formData.value.autoCreateAsset.value.value,
    };

    if (formData.value.defaultLocation.value !== "") {
      payload.default_location_id = formData.value.defaultLocation.value;
    }

    return payload;
  },
  create(formData: Ref, kandjiFieldsMapping: Ref): void {
    const payload: Payload = this.getCreatePayload(
      formData,
      kandjiFieldsMapping
    );
    createKandjiSetting(payload).then(() => {
      this.list();
    });
  },
  update(id: string, formData: Ref, kandjiFieldsMapping: Ref): void {
    const payload: Payload = this.getCreatePayload(
      formData,
      kandjiFieldsMapping
    );
    updateKandjiSetting(id, payload).then(() => {
      this.list();
    });
  },
  reset(): void {
    state.kandjiSettings = {
      id: "",
      api_url: "",
      api_token: "",
      default_asset_type_id: "",
      default_location_id: "",
      auto_assign_to_user: false,
      kandji_fields_mapping: {},
      auto_create_asset: false,
      last_sync: null,
      sync_report: {},
    };
  },
};

export default {
  state,
  methods,
};
