enum DATE_PATTERNS {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'ISO-8601' = 'ISO-8601',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'MM.DD.YYYY' = 'MM.DD.YYYY',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'YYYYMMDD' = 'YYYYMMDD'
}

export { DATE_PATTERNS };
