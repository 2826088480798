<script setup lang="ts">
import { Modal, ModalTransition } from '@atlaskit/onboarding';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

const model = defineModel<boolean>({ required: true });

defineProps<{
  actions: ComponentProps<typeof Modal>['actions'];
  heading: ComponentProps<typeof Modal>['heading'];
  image: ComponentProps<typeof Modal>['image'];
}>();

const AtlasModal = applyPureReactInVue(Modal);
const AtlasModalTransition = applyPureReactInVue(ModalTransition);
</script>

<template>
  <AtlasModalTransition>
    <AtlasModal
      v-if="model"
      :actions="actions"
      :heading="heading"
      :image="image"
    >
      <slot />
    </AtlasModal>
  </AtlasModalTransition>
</template>
