import { APIKeysApi } from './APIKeys';
import { customFieldsApi } from './customFields';
import { eventsApi } from './events';
import { integrationsApi } from './integrations';
import { portalApi } from './portal';
import { settingsApi } from './settings';

export const api = {
  ...settingsApi,
  ...APIKeysApi,
  ...eventsApi,
  ...customFieldsApi,
  ...integrationsApi,
  ...portalApi,
};
