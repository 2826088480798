import { reactive, type Ref } from 'vue';
import type { AxiosResponse, AxiosError } from 'axios';
import he from 'he';

import type {
  Asset,
  AttachmentPayload,
  Comment,
  CommentPayload,
  DeleteBulkPayload,
  ExportData,
  ExportPayload,
  FieldValue,
  Import,
  ImportPayload,
  ImportStatus,
  ListParams,
  Payload,
  State,
  Handler,
} from './types';

import utils from '@/utils';
import auth from '@/store/auth';
import {
  checkBulkAssets,
  createComment,
  deleteAsset,
  deleteAssetAttachments,
  deleteBulkAssets,
  deleteComment,
  exportAssets,
  getAsset,
  getAssetAttachments,
  getAssetComments,
  getAssetHistory,
  getAssetPanel,
  getAssets,
  getLinkedIssues,
  importAssets,
  importAssetStatus,
  postAssets,
  postSampleAsset,
  updateComment,
  updateAsset,
  getAssetsPageNum,
} from '@/api/assets.api';
import type { LinkedIssue, TableColumn } from '../accessories/types';
import type { AssetType } from '../assettypes/types';
import type { AssetField } from '../assetfields/types';
import type { User } from '../users/types';
import { getLinkedAssetChoices } from '../../api/assets.api';

// state
const state: State = reactive({
  assets: [],
  filteredAssets: [],
  linkedAssetChoices: [],
  currentAsset: {
    id: '',
    name: '',
    assettype: null,
    location: null,
    loanee_text: '',
    field_values: [],
    parents: [],
    children: [],
    intune_id: '',
    kandji_id: '',
  },
  tableColumns: [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'type', label: 'Type', sortable: true },
  ],
  reportTableColumns: [
    { key: 'id', label: '#', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'asset_type', label: 'Type', sortable: true },
    { key: 'location', label: 'Location', sortable: true },
    { key: 'loaneeText', label: 'Loanee', sortable: true },
  ],
  selectedColumns: [
    { key: 'location', label: 'Location', sortable: true },
    { key: 'loaneeText', label: 'Loanee', sortable: true },
  ],
  assetComments: [],
  assetHistory: [],
  assetTypeFilter: [],
  locationFilter: [],
  loaneeFilter: '',
  queryFilter: '',
  isFilter: false, // track if the result is a filter queryset
  idColImportName: '',
  importType: '',
  importId: '',
  importStatus: {
    import_status: 'in_progress',
    num_success: 0,
    num_created: 0,
    num_updated: 0,
  },
  linkedIssues: [],
  assetPanel: {
    id: '',
    name: '',
    assettype: null,
    location: null,
    loanee_text: '',
    field_values: [],
    parents: [],
    children: [],
    intune_id: '',
    kandji_id: '',
  },
  exportedAssets: {
    exported_assets: [],
  },
  exportTableColumns: [
    { key: 'ID', label: '#', sortable: false },
    { key: 'Name', label: 'Name', sortable: false },
    { key: 'Asset Type', label: 'Asset Type', sortable: false },
    { key: 'Location', label: 'Location', sortable: false },
    { key: 'Loanee', label: 'Loanee', sortable: false },
  ],
  exportSelectedFields: [],
  haveAssetsLoaded: false,
  assetAttachments: {
    bucket: '',
    attachments: [],
  },
  matchingAssets: 0,
  assetFound: true,
  isListBeingFetched: false,
  fetchAssetFilter: false, // track whether it is a filter list query
});

// methods
const methods = {
  async list(shouldReset = true): Promise<void> {
    // ensure that there is only one list call
    // otherwise pagination will cause multiple duplicate
    // requests to be issued
    if (state.isListBeingFetched === true && state.fetchAssetFilter === false) {
      return;
    }

    state.isListBeingFetched = true;
    state.isFilter = false;

    const listParams: ListParams = {};
    listParams.assettype = state.assetTypeFilter;
    listParams.location = state.locationFilter;

    if (state.assetTypeFilter.length !== 0) {
      state.isFilter = true;
    }

    if (state.locationFilter.length !== 0) {
      state.isFilter = true;
    }

    if (state.queryFilter != '') {
      listParams.query = state.queryFilter;
      state.isFilter = true;
    }

    await getAssets(listParams, true).then((response: AxiosResponse) => {
      state.assets = response.data.assets;
      state.haveAssetsLoaded = true;

      if (shouldReset) {
        this.reset();
        this.initSelectedColumns();
      }

      if (response.data.nextPage !== -1) {
        this.getAssetsRecursive(listParams, response.data.nextPage);
      } else {
        state.isListBeingFetched = false;
        this.checkAndDisableFetchAssetFilter(listParams);
      }
    });
  },
  async getAssetsRecursive(listParams: ListParams, pageNum: number): Promise<void> {
    await getAssetsPageNum(listParams, pageNum).then((response: AxiosResponse) => {
      // check if another filter query ran after the original, cancel and return if true
      if (
        utils.compareArrays(listParams.assettype, state.assetTypeFilter) === false ||
        utils.compareArrays(listParams.location, state.locationFilter) === false ||
        (state.queryFilter && listParams.query !== state.queryFilter)
      ) {
        return;
      }

      state.assets = state.assets.concat(response.data.assets);

      if (response.data.nextPage !== -1) {
        this.getAssetsRecursive(listParams, response.data.nextPage);
      } else {
        state.isListBeingFetched = false;
        this.checkAndDisableFetchAssetFilter(listParams);
      }
    });
  },
  checkAndDisableFetchAssetFilter(listParams: ListParams): void {
    // check and disable fetchAssetFilter if the filter query is the same as the current filter
    if (
      state.fetchAssetFilter &&
      ((state.assetTypeFilter && listParams.assettype && listParams.assettype === state.assetTypeFilter) ||
        (state.locationFilter && listParams.location && listParams.location === state.locationFilter) ||
        (state.queryFilter && listParams.query && listParams.query === state.queryFilter))
    ) {
      state.fetchAssetFilter = false;
    }
  },
  create(formData: Ref, _callback: Handler): void {
    const payload: Payload = this.getCreatePayload(formData);
    postAssets(payload).then((response: AxiosResponse) => {
      this.reset();
      state.currentAsset = response.data;
      _callback(state.currentAsset.id);
    });
  },
  createSample(): void {
    postSampleAsset().then(() => {
      this.reset();
      this.list();
    });
  },
  async update(id: string, formData: Ref, _callback: Handler): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    await updateAsset(id, payload).then((response: AxiosResponse) => {
      state.currentAsset = response.data;
      _callback(state.currentAsset.id);
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      asset_type_id: formData.value.asset_type.value,
      loanee_id: formData.value.loanee_id.value,
      field_values: JSON.stringify(formData.value.fields.value),
      updated_by: JSON.stringify({
        id: auth.state.atlassianId,
        name: auth.state.atlassianUsername,
      }),
      parents: formData.value.parents.value,
    };
    if (formData.value.location.value !== '') {
      payload.location_id = formData.value.location.value;
    }
    return payload;
  },
  processAssetFields(currentAsset: Asset): Array<FieldValue> {
    const allFields: Array<FieldValue> = [];

    currentAsset.field_values.forEach((item) => {
      const newField = item;
      if (newField.values !== undefined && newField.values !== null) {
        newField.values = newField.values.replace(/\s*,\s*/g, ',').trim();
      }

      allFields.push(newField);
    });
    return allFields;
  },
  addAssetFields(currentAsset: Asset, allAssetTypes: AssetType[]): AssetField[] {
    const allFields: AssetField[] = [];

    allAssetTypes.forEach((at: AssetType) => {
      if (at.id == currentAsset.assettype?.id) {
        at.fields.forEach((item) => {
          const newField = item;
          if (newField.values !== undefined && newField.values !== null) {
            newField.values = newField.values.replace(/\s*,\s*/g, ',').trim();
          }
          if (!newField.value) {
            newField.value = '';
          }
          currentAsset.field_values.forEach((fv) => {
            if (
              fv.name == newField.name &&
              fv.value !== null &&
              fv.value !== undefined &&
              typeof fv.value === 'string'
            ) {
              newField.value = fv.value.trim();
              if (newField.field_type === 'date') {
                newField.value = utils.renderStringToDt(newField.value);
              } else {
                newField.value = he.decode(newField.value);
              }
            }
          });
          allFields.push(newField);
        });
      }
    });

    return allFields;
  },
  updateAssetFields(currentAsset: Asset, selectedAssetType: AssetType): AssetField[] {
    const allFields: AssetField[] = [];

    selectedAssetType.fields.forEach((item) => {
      const newField = item;
      if (newField.values !== undefined && newField.values !== null) {
        newField.values = newField.values.replace(/\s*,\s*/g, ',').trim();
      }
      if (!newField.value) {
        newField.value = '';
      }
      currentAsset.field_values.forEach((fv) => {
        if (
          fv.name == newField.name &&
          fv.value !== null &&
          fv.value !== undefined &&
          typeof fv.value === 'string'
        ) {
          newField.value = fv.value.trim();
          if (newField.field_type === 'date') {
            newField.value = utils.renderStringToDt(newField.value);
          } else {
            newField.value = he.decode(newField.value);
          }
        }
      });
      allFields.push(newField);
    });

    return allFields;
  },
  set(asset: Asset): void {
    state.currentAsset = { ...state.currentAsset, ...asset };
  },
  async get(id: string): Promise<void> {
    await getAsset(id)
      .then((response: AxiosResponse<Asset>) => {
        state.currentAsset = response.data;
        state.currentAsset.field_values = this.processAssetFields(state.currentAsset);
        state.assetFound = true;
      })
      .catch((error) => {
        if (error.response?.status == 404) {
          state.assetFound = false;
        }
      });
  },
  delete(id: string): void {
    deleteAsset(id).then(() => {
      state.assets = this.removeDeletedAssets([id]);
      this.reset();
    });
  },
  async deleteBulk(ids: Array<string>): Promise<void> {
    const payload: DeleteBulkPayload = {
      asset_ids: JSON.stringify(ids),
    };
    await deleteBulkAssets(payload).then(() => {
      state.assets = this.removeDeletedAssets(ids);
      this.reset();
    });
  },
  removeDeletedAssets(deletedAssetIds: Array<string>): Asset[] {
    const remainingAssets: Asset[] = [];
    state.assets.forEach((item) => {
      if (deletedAssetIds.includes(item.id) === false) {
        remainingAssets.push(item);
      }
    });
    return remainingAssets;
  },
  checkBulk(ids: Array<string>): void {
    const payload: DeleteBulkPayload = {
      asset_ids: JSON.stringify(ids),
    };
    checkBulkAssets(payload).then((response: AxiosResponse) => {
      state.matchingAssets = response.data;
    });
  },
  reset(): void {
    state.currentAsset = {
      id: '',
      name: '',
      assettype: null,
      location: null,
      loanee_text: '',
      field_values: [],
      parents: [],
      children: [],
      intune_id: '',
      kandji_id: '',
    };
    state.matchingAssets = 0;
    state.assetFound = true;
  },
  getComments(id: string): void {
    getAssetComments(id).then((response: AxiosResponse<Comment[]>) => {
      state.assetComments = response.data;
    });
  },
  getHistory(id: string): void {
    getAssetHistory(id).then((response: AxiosResponse) => {
      state.assetHistory = response.data;
    });
  },
  addComment(id: string, payload: CommentPayload): void {
    createComment(id, payload).then((response: AxiosResponse<Comment>) => {
      state.assetComments.push(response.data);
    });
  },
  editComment(id: number, payload: CommentPayload): void {
    updateComment(id, payload).then((response: AxiosResponse<Comment>) => {
      state.assetComments = state.assetComments.map((comment) => {
        if (comment.id === id) {
          return response.data;
        }
        return comment;
      });
    });
  },
  removeComment(id: number): void {
    deleteComment(id).then(() => {
      state.assetComments = state.assetComments.filter((comment) => comment.id !== id);
    });
  },
  updateTableColumns(cols: Array<string>): void {
    const newTableColumns: Array<TableColumn> = [];

    cols.forEach((col: string) => {
      if (col === 'Asset #') {
        newTableColumns.push({
          key: 'id',
          label: '#',
          sortable: true,
        });
      } else if (col === 'Loanee') {
        newTableColumns.push({
          key: 'loaneeText',
          label: 'Loanee',
          sortable: true,
        });
      } else if (col === 'Location') {
        newTableColumns.push({
          key: 'location',
          label: 'Location',
          sortable: true,
        });
      } else {
        newTableColumns.push({
          key: col,
          label: col,
          sortable: true,
        });
      }
    });

    state.selectedColumns = newTableColumns;
    if (utils.supportsLocalStorage()) {
      const backfillDefaultColumns = localStorage.getItem('assetTableAddDefaultCols');
      if (backfillDefaultColumns === null) {
        localStorage.setItem('assetTableAddDefaultCols', 'true');
      }
      localStorage.setItem('assetTableSelectedColumns', JSON.stringify(state.selectedColumns));
    }
  },
  initSelectedColumns(): void {
    if (utils.supportsLocalStorage()) {
      let fields = localStorage.getItem('assetTableSelectedColumns');
      const backfillDefaultColumns = localStorage.getItem('assetTableAddDefaultCols');
      if (fields !== null && JSON.parse(fields).some((field: TableColumn) => !field.sortable)) {
        const newFields = JSON.parse(fields).map((field: TableColumn) => ({
          ...field,
          sortable: true,
        }));
        localStorage.setItem('assetTableSelectedColumns', JSON.stringify(newFields));
        fields = localStorage.getItem('assetTableSelectedColumns');
      }
      if (
        fields !== null &&
        JSON.parse(fields).some((field: TableColumn) => field.label === '#' && field.key === '#')
      ) {
        const newFields = JSON.parse(fields).map((field: TableColumn) => {
          if (field.label === '#') {
            return { ...field, key: 'id' };
          }
          return field;
        });
        localStorage.setItem('assetTableSelectedColumns', JSON.stringify(newFields));
        fields = localStorage.getItem('assetTableSelectedColumns');
      }
      if (fields !== null) {
        if (backfillDefaultColumns === null) {
          state.selectedColumns = state.selectedColumns.concat(JSON.parse(fields));
          localStorage.setItem('assetTableAddDefaultCols', 'true');
          localStorage.setItem('assetTableSelectedColumns', JSON.stringify(state.selectedColumns));
        } else {
          state.selectedColumns = JSON.parse(fields);
        }
      }
    }
  },
  async updateAssetTypeFilter(assetTypeSelected: Array<string>): Promise<void> {
    const assetTypeFilter = assetTypeSelected;

    // asset type filter hasn't changed - return
    if (assetTypeFilter === state.assetTypeFilter) {
      return;
    }

    state.assetTypeFilter = assetTypeFilter;
    state.fetchAssetFilter = true;
    await this.list();
  },
  async updateLocationFilter(locationSelected: string[]): Promise<void> {
    const locationFilter = locationSelected;

    // location filter hasn't changed - return
    if (locationFilter === state.locationFilter) {
      return;
    }

    state.locationFilter = locationFilter;
    state.fetchAssetFilter = true;
    await this.list();
  },
  async updateQueryFilter(query: string): Promise<void> {
    // Query filter hasn't changed - return
    if (query === state.queryFilter) {
      return;
    }
    state.queryFilter = query;
    state.fetchAssetFilter = true;
    await this.list();
  },
  async updateLoaneeFilter(loaneeSelected: User): Promise<void> {
    state.locationFilter = [];
    state.assetTypeFilter = [];
    state.loaneeFilter = loaneeSelected.id;
    if (state.loaneeFilter) {
      await this.list();
    }
  },
  import(column_mapping: Array<string>, records: Array<string>, import_type: string): void {
    const payload: ImportPayload = {
      column_mapping: JSON.stringify(column_mapping),
      asset_data: JSON.stringify(records),
      import_type: import_type,
    };
    importAssets(payload).then((response: AxiosResponse<Import>) => {
      state.importId = response.data.id;
    });
  },
  importStatus(): void {
    if (state.importId !== '') {
      importAssetStatus(state.importId).then((response: AxiosResponse<ImportStatus>) => {
        state.importStatus.import_status = response.data.import_status;
        state.importStatus.num_success = response.data.num_success;
        state.importStatus.num_created = response.data.num_created;
        state.importStatus.num_updated = response.data.num_updated;
      });
    }
  },
  getIssues(assetId: string): void {
    getLinkedIssues(assetId).then((response: AxiosResponse<LinkedIssue[]>) => {
      state.linkedIssues = response.data;
    });
  },
  async getPanel(assetId: string): Promise<void> {
    await getAssetPanel(assetId)
      .then((response: AxiosResponse<Asset>) => {
        state.assetPanel = response.data;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          state.assetFound = false;
        }
      });

    setTimeout(() => {
      // @ts-expect-error removing help scout
      window.Beacon('destroy');
    }, 250);
  },
  export(asset_type_ids: Array<string>, location_ids: Array<string>, asset_fields: Array<string>): void {
    const payload: ExportPayload = {
      location_ids: JSON.stringify(location_ids),
      asset_type_ids: JSON.stringify(asset_type_ids),
      asset_fields: JSON.stringify(asset_fields),
    };
    exportAssets(payload).then((response: AxiosResponse<ExportData>) => {
      state.exportedAssets = response.data;
      const fieldColumns: Array<TableColumn> = [];
      asset_fields.forEach((af) => {
        fieldColumns.push({
          key: af,
          label: af,
          sortable: false,
        });
      });
      state.exportSelectedFields = fieldColumns;
    });
  },
  getLinkedAssetChoices(assetId: string): Asset[] {
    const choices: Asset[] = [];
    state.assets.forEach((asset) => {
      if (asset.id.toString() !== assetId) {
        choices.push(asset);
      }
    });
    return choices;
  },
  getAttachments(id: string): void {
    getAssetAttachments(id).then((response: AxiosResponse<AttachmentPayload>) => {
      state.assetAttachments = response.data;
    });
  },
  deleteAttachment(attachmentId: string, assetId: string): void {
    deleteAssetAttachments(attachmentId, assetId).then();
  },
  listLinkedAssetChoices(): void {
    getLinkedAssetChoices().then((response: AxiosResponse<Asset[]>) => {
      state.linkedAssetChoices = response.data;
    });
  },
  resetQueryFilter(): void {
    state.queryFilter = '';
    state.assetTypeFilter = [];
    state.locationFilter = [];
  },
};

export default {
  state,
  methods,
};
