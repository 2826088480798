<script setup lang="ts">
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  size?: ComponentProps<typeof SuccessIcon>['size'];
  primaryColor?: ComponentProps<typeof SuccessIcon>['primaryColor'];
  secondaryColor?: ComponentProps<typeof SuccessIcon>['secondaryColor'];
  label?: ComponentProps<typeof SuccessIcon>['label'];
}>();

const AtlasSuccessIcon = applyPureReactInVue(SuccessIcon);
</script>

<template>
  <AtlasSuccessIcon
    :size="size"
    :primaryColor="primaryColor"
    :secondaryColor="secondaryColor"
    :label="label"
  />
</template>
