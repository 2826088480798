import type { Payload } from "@/store/kandji/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/kandji-settings";

// List
const getKandjiSettings = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(END_POINT, config);
};

// Create Kandji Setting
const createKandjiSetting = (payload: Payload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`${END_POINT}`, payload, config);
};

// Update Kandji Setting
const updateKandjiSetting = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.put(`${END_POINT}/${id}`, payload, config);
};

export { getKandjiSettings, updateKandjiSetting, createKandjiSetting };
