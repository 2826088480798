import type { Payload } from "@/store/intune/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/intune-settings";

// List
const getIntuneSettings = (): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = { headers: { isBlocking: true } };
    return apiClient.get(END_POINT, config);
};

// Update Intune Setting
const updateIntuneSetting = (
    id: string,
    payload: Payload
): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = { headers: { isBlocking: true } };
    return apiClient.put(`${END_POINT}/${id}`, payload, config);
};

// Delete Intune Setting
const deleteIntuneSetting = (
    id: string,
): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = { headers: { isBlocking: true } };
    return apiClient.delete(`${END_POINT}/${id}`, config);
};

export {
    getIntuneSettings,
    updateIntuneSetting,
    deleteIntuneSetting,
};
