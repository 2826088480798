import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';
import {
  CalendarIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  DocumentIcon,
  DownloadIcon,
  InformationCircleIcon,
  PlusIcon,
  PlusCircleIcon,
  SearchIcon,
  UploadIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/vue/solid";

import {
  CalendarIcon as CalendarIcon_,
  CheckIcon as CheckIcon_,
  CheckCircleIcon as CheckCircleIcon_,
  ChevronDownIcon as ChevronDownIcon_,
  DocumentIcon as DocumentIcon_,
  DownloadIcon as DownloadIcon_,
  InformationCircleIcon as InformationCircleIcon_,
  PlusIcon as PlusIcon_,
  PlusCircleIcon as PlusCircleIcon_,
  SearchIcon as SearchIcon_,
  UploadIcon as UploadIcon_,
  XCircleIcon as XCircleIcon_,
  XIcon as XIcon_,
} from "@heroicons/vue/outline";
import { TEXT_COLORS as COLORS } from '@Types';

const ICONS = {
  CalendarIcon,
  CalendarIcon_,
  CheckIcon,
  CheckIcon_,
  CheckCircleIcon,
  CheckCircleIcon_,
  ChevronDownIcon,
  ChevronDownIcon_,
  DocumentIcon,
  DocumentIcon_,
  DownloadIcon,
  DownloadIcon_,
  InformationCircleIcon,
  InformationCircleIcon_,
  PlusIcon,
  PlusIcon_,
  PlusCircleIcon,
  PlusCircleIcon_,
  SearchIcon,
  SearchIcon_,
  UploadIcon,
  UploadIcon_,
  XCircleIcon,
  XCircleIcon_,
  XIcon,
  XIcon_,
};

enum SIZES {
  CUSTOM = '',
  XSMALL = 'is-xsmall',
  SMALL = 'is-small',
  XMEDIUM = 'is-xmedium',
  MEDIUM = 'is-medium',
  LARGE = 'is-large',
  XLARGE = 'is-xlarge',
}

interface Prop {
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps>;
  color?: COLORS
  size?: SIZES,
}

const AppIconProps = {
  ICONS,
  COLORS,
  SIZES,
};

export {
  AppIconProps,
  ICONS,
  SIZES,
  // type Prop, // TODO :: review after vite migration [AMFJ-46]
  Prop,
};