<script setup lang="ts">
import { computed } from 'vue';
import { APPEARANCES } from '@Types';
import type { Emit, Prop } from './types';
import { ATTRS_NAMES, ROLES, SPACINGS, TAGS } from './types';
import AppIcon from '../AppIcon/AppIcon.vue';
import AppLoader from '../AppLoader/AppLoader.vue';

const emit = defineEmits<Emit>();

const props = withDefaults(defineProps<Prop>(), {
  spacing: SPACINGS.DEFAULT,
  role: ROLES.BUTTON,
  variation: APPEARANCES.PRIMARY,
  tag: TAGS.BUTTON,
});

const attr = computed(() => {
  const { HREF, TYPE } = ATTRS_NAMES;
  const { role, url } = props;

  return {
    name: props.tag === TAGS.LINK ? HREF : TYPE,
    value: props.tag === TAGS.LINK ? url : role,
  };
});

const cssClasses = computed(() => [
  'button',
  `button-spacing-${props.spacing}`,
  props.variation,
  props.disabled ? 'is-disabled' : '',
  props.selected ? 'is-selected' : '',
  props.loading ? 'is-loading' : '',
  props.wide ? 'w-full' : '',
]);

function onClick() {
  const isExternalLink = props.url?.startsWith('http');
  if (isExternalLink) {
    window.open(props.url, '_blank');
  }

  emit('click');
}
</script>

<template>
  <component
    :is="tag"
    :class="cssClasses"
    :[attr.name]="attr.value"
    @click="onClick"
  >
    <span
      v-if="loading"
      class="button-loader"
    >
      <AppLoader :variation="variation" />
    </span>

    <span class="content">
      <AppIcon
        v-if="iconLeft"
        :icon="iconLeft"
      />

      {{ label }}

      <AppIcon
        v-if="iconRight"
        :icon="iconRight"
      />
    </span>
  </component>
</template>

<style scoped>
@import url('./style.css');
</style>
