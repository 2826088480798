<script setup lang="ts">
import { Code } from '@atlaskit/code';
import { applyPureReactInVue } from 'veaury';

const AtlasCode = applyPureReactInVue(Code);
</script>

<template>
  <AtlasCode>
    <slot />
  </AtlasCode>
</template>
