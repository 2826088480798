import posthog from 'posthog-js';

posthog.init(import.meta.env.VITE_APP_POSTHOG_PROJECT_KEY, {
  api_host: 'https://app.posthog.com',
  capture_pageview: false,
});

posthog.group('organization', window.location.hostname);

export default posthog;
