import { reactive, type Ref } from "vue";
import type { CustomField, Payload, State } from "./types";

import {
  getCustomFields,
  postCustomFields,
  updateCustomField,
  deleteCustomField,
  postSyncIssues,
} from "@/api/customfields.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  customFields: [],
  currentFieldToSync: {
    id: "",
    custom_field_name: "",
    custom_field_jira_id: "",
    amfj_field_name: "",
    field: {
      id: "",
      name: "",
      field_type: "",
      required: false,
    },
    screens_count: 0,
    contexts_count: 0,
  },
  tableColumns: [
    { key: "custom_field_name", label: "Custom Field Name", sortable: false },
    { key: "amfj", label: "Asset Field", sortable: false },
    { key: "screens", label: "Screens and Contexts", sortable: false },
    { key: "update", label: "", sortable: false },
  ],
});

// methods
const methods = {
  async list(): Promise<void> {
    await getCustomFields().then((response: AxiosResponse<CustomField[]>) => {
      state.customFields = response.data;
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      custom_field_name: formData.value.customFieldName.value,
      amfj_field_name: formData.value.amfjField.value,
    };
    return payload;
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    postCustomFields(payload).then(() => {
      this.list();
    });
  },
  update(id: string, formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    updateCustomField(id, payload).then(() => {
      this.list();
    });
  },
  delete(id: string): void {
    deleteCustomField(id).then(() => {
      this.reset();
      this.list();
    });
  },
  setCurrentFieldToSync(id: string): void {
    state.currentFieldToSync = state.customFields.find(
      (field) => field.id === id
    );
  },
  reset(): void {
    state.currentFieldToSync = {
      id: "",
      custom_field_name: "",
      custom_field_jira_id: "",
      amfj_field_name: "",
      field: {
        id: "",
        name: "",
        field_type: "",
        required: false,
      },
      screens_count: 0,
      contexts_count: 0,
    };
  },
  async syncIssues(): Promise<void> {
    await postSyncIssues();
  },
};

export default {
  state,
  methods,
};
