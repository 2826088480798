import type { Payload } from "@/store/alerts/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/alerts";

// List
const getAlerts = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(END_POINT, config);
};

// Create Alert
const postAlerts = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Alert
const updateAlert = (id: string, payload: Payload): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Get Alert
const getAlert = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}`);
};

// Delete Alert
const deleteAlert = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

export { getAlerts, postAlerts, updateAlert, getAlert, deleteAlert };
