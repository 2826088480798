<script setup lang="ts">
import { AutoDismissFlag } from '@atlaskit/flag';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  title: ComponentProps<typeof AutoDismissFlag>['title'];
  id: ComponentProps<typeof AutoDismissFlag>['id'];
  appearance?: ComponentProps<typeof AutoDismissFlag>['appearance'];
  description?: ComponentProps<typeof AutoDismissFlag>['description'];
}>();

const AtlasAutoDismissFlag = applyPureReactInVue(AutoDismissFlag);
</script>

<template>
  <AtlasAutoDismissFlag
    :id="id"
    :title="title"
    :appearance="appearance"
  >
    <template #node:icon>
      <slot />
    </template>
    <template #node:description>
      <slot name="description">
        {{ description }}
      </slot>
    </template>
  </AtlasAutoDismissFlag>
</template>
