<script setup lang="ts">
import { computed } from 'vue';
import type { Prop } from './types';
import { VARIATIONS } from './types';
import { HeaderUpload, HeaderMap } from './components';

const props = withDefaults(defineProps<Prop>(), {
  variation: VARIATIONS.UPLOAD,
});

const isUpload = computed(() => props.variation === VARIATIONS.UPLOAD);
</script>

<template>
  <header class="header-upload">
    <section class="instructions">
      <HeaderUpload v-if="isUpload" />

      <HeaderMap v-else />
    </section>

    <section class="actions">
      <AppButton
        v-if="isUpload"
        class="max-w-[223px]"
        download="CSVTemplate"
        href="https://elasticbeanstalk-us-west-2-641879703613.s3.us-west-2.amazonaws.com/Sample+Assets.csv"
        label="Download CSV template"
        :iconLeft="$AppButtonProps.ICONS.DownloadIcon_"
        :variation="$AppButtonProps.VARIATIONS.PRIMARY_LIGHT"
        :tag="$AppButtonProps.TAGS.LINK"
      />

      <div
        v-else-if="mapState?.columns"
        class="assets-csv-state"
      >
        <div class="assets-csv-found">
          <AppIcon
            :color="$AppIconProps.COLORS.SLATE_600"
            :icon="$AppIconProps.ICONS.InformationCircleIcon_"
          />

          <AppText
            :color="$AppTextProps.COLORS.SLATE_600"
            :size="$AppTextProps.SIZES.H500_SUB_SECTION_HEADING"
          >
            <strong>{{ mapState.rows }}</strong> rows and
            <strong>{{ mapState.columns }}</strong> columns found
          </AppText>
        </div>

        <div :class="['assets-csv-mapped', { 'opacity-0': !mapState.mapped }]">
          <AppIcon
            :color="$AppIconProps.COLORS.SLATE_600"
            :icon="$AppIconProps.ICONS.CheckIcon_"
          />

          <AppText
            :color="$AppTextProps.COLORS.SLATE_600"
            :size="$AppTextProps.SIZES.H500_SUB_SECTION_HEADING"
          >
            <strong>{{ mapState.mapped }}</strong> columns ready to import
          </AppText>
        </div>
      </div>
    </section>
  </header>
</template>

<style scoped>
.header-upload {
  @apply flex flex-col gap-4;
}
.instructions {
  @apply max-w-[685px];
}
.actions {
  @apply flex flex-col justify-end;
}
.highlight {
  @apply font-sans font-semibold text-slate-800;
}

.assets-csv-state {
  @apply flex flex-col gap-2 items-end;
}

.assets-csv-found {
  @apply bg-slate-100 flex gap-2 pb-1 px-2.5 rounded w-[max-content];
}

.assets-csv-mapped {
  @apply bg-green-100 flex gap-2 py-1 px-2.5 rounded w-[fit-content];
}

@media (min-width: 600px) {
  .assets-csv-state {
    @apply flex-row items-start;
  }

  .actions {
    @apply block pl-0;
  }
}

@media (min-width: 992px) {
  .header-upload {
    @apply flex-row justify-between;
  }
  .assets-csv-state {
    @apply flex-col items-end;
  }

  .actions {
    @apply flex;
  }
}
</style>
