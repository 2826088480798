<script setup lang="ts">
import { computed } from 'vue';
import type { Prop } from './types';
import { SIZES } from './types';

const props = withDefaults(defineProps<Prop>(), {
  size: SIZES.XMEDIUM,
});

const cssClasses = computed(() => ['app-icon', props.color ?? 'text-inherit', props.size]);
</script>

<template>
  <component
    :class="[cssClasses]"
    :is="icon"
  />
</template>

<style scoped>
.app-icon.is-xsmall {
  @apply h-2 w-2;
}

.app-icon.is-small {
  @apply h-4 w-4;
}

.app-icon.is-xmedium {
  @apply h-5 w-5;
}

.app-icon.is-medium {
  @apply h-6 w-6;
}

.app-icon.is-large {
  @apply h-12 w-12;
}

.app-icon.is-xlarge {
  @apply h-24 w-24;
}
</style>
