<script setup lang="ts">
import { computed } from 'vue';
import { APPEARANCES } from '@Types';
import type { Prop, Variation } from './types';
import { SIZES } from './types';

const props = withDefaults(defineProps<Prop>(), {
  size: SIZES.MEDIUM,
  variation: APPEARANCES.DEFAULT,
});

const variations = computed(() => {
  let variations: Variation = {};

  Object.values(APPEARANCES).forEach((value) => {
    variations[value] = `border-${value}`;
  });

  return variations;
});

const variation = computed(() => variations.value[props.variation]);

const cssClasses = computed(() => ['base-loader', props.size, variation.value]);
</script>

<template>
  <div :class="cssClasses" />
</template>

<style scoped>
.base-loader {
  @apply rounded-full;
  animation: amfj-loading 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none
    running;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
}

.base-loader.is-xsmall {
  @apply h-2 w-2 border-2;
}

.base-loader.is-small {
  @apply border-[3px] h-4 w-4;
}

.base-loader.is-medium {
  @apply h-6 w-6 border-4;
}

.base-loader.is-large {
  @apply border-[6px] h-12 w-12;
}

.base-loader.is-xlarge {
  @apply h-24 w-24 border-8;
}

@keyframes amfj-loading {
  to {
    transform: rotate(1turn);
  }
}
</style>
