import type { Payload } from "@/store/users/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/users";

// List Users
const getUsers = (isBlocking: boolean): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: isBlocking } };
  return apiClient.get(END_POINT, config);
};

// Get User
const getUserById = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(`${END_POINT}/${id}`, config);
};

const getUserByName = (name: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(`${END_POINT}/${name}`, config);
};

// Create User
const postUser = (payload: Payload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(END_POINT, payload, config);
};

// Update User
const updateUser = (id: string, payload: Payload): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Delete User
const deleteUser = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

// User
const getUser = (uid: string) => {
  const userEndpoint = "/jira-user";
  const config: AxiosRequestConfig = {
    params: { uid },
  };
  return apiClient.get(userEndpoint, config);
};

// Groups
const getGroups = () => {
  const groupsEndpoint = "/jira-groups";
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(groupsEndpoint, config);
};

// Sync users
const postSyncUsers = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`${END_POINT}/sync`, {}, config);
};

export {
  getUsers,
  getUserById,
  getUser,
  getGroups,
  postUser,
  updateUser,
  deleteUser,
  postSyncUsers,
  getUserByName,
};
