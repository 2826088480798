import { reactive, type Ref } from "vue";
import type { AssetField, Payload, State } from "./types";
import assetTypes from "../assettypes/index";

import auth from "@/store/auth";
import type { AxiosResponse } from "axios";
import {
  deleteAssetField,
  getAssetField,
  getAssetFields,
  postAssetFields,
  updateAssetField,
} from "@/api/assetfields.api";

// state
const state: State = reactive({
  asset_fields: [],
  currentAssetField: {
    id: "",
    name: "",
    field_type: "",
    values: "",
    description: "",
    required: false,
  },
  createdAssetField: {
    id: "",
    name: "",
    field_type: "",
    values: "",
    description: "",
    required: false,
  },
  editedAssetField: {
    id: "",
    name: "",
    field_type: "",
    values: "",
    description: "",
    required: false,
  },
});

// methods
const methods = {
  async list(): Promise<void> {
    await getAssetFields().then((response: AxiosResponse<AssetField[]>) => {
      state.asset_fields = response.data;
    });
  },
  async create(formData: Ref): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    await postAssetFields(payload).then((res) => {
      assetTypes.state.currentAssetType.fields.push(res.data);
      this.reset();
      this.list();
      state.createdAssetField = res.data;
    });
  },
  update(id: string, formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    updateAssetField(id, payload).then(
      (response: AxiosResponse<AssetField>) => {
        this.reset();
        this.list();
        state.editedAssetField = response.data;
      }
    );
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      field_type: formData.value.field_type.value.toLowerCase(),
      values: formData.value.field_values?.value,
      required: formData.value.required.value,
      updated_by: auth.state.atlassianUsername,
    };
    return payload;
  },
  get(id: string): void {
    getAssetField(id).then((response: AxiosResponse<AssetField>) => {
      state.currentAssetField = response.data;
    });
  },
  setCurrentAssetField(id: string): void {
    state.asset_fields.forEach((af: AssetField) => {
      if (af.id === id) {
        state.currentAssetField = af;
      }
    });
  },
  delete(id: string): void {
    deleteAssetField(id).then(() => {
      this.list();
      this.reset();
    });
  },
  reset(): void {
    state.currentAssetField = {
      id: "",
      name: "",
      field_type: "",
      values: "",
      description: "",
      required: false,
    };
    state.createdAssetField = {
      id: "",
      name: "",
      field_type: "",
      values: "",
      description: "",
      required: false,
    };
  },
  updateAssetFields(fields: AssetField[]): void {
    state.asset_fields = fields;
  },
};

export default {
  state,
  methods,
};
