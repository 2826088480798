import { reactive } from "vue";
import type { State } from "./types";

// state
const state: State = reactive({
  loadingStatus: 0,
  errorNotification: false,
  errorMessage: "",
});

// methods
const methods = {
  startLoading(): void {
    state.loadingStatus++;
  },
  finishLoading(): void {
    if (state.loadingStatus > 0) {
      state.loadingStatus--;
    }
  },
  setError(status: boolean, message: string): void {
    state.errorNotification = status;
    state.errorMessage = message;
  },
};

export default {
  state,
  methods,
};
