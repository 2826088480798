import { APPEARANCES } from '@Types';
import { VARIATIONS } from '../AppButton/types';

enum SIZES {
  XSMALL = 'is-xsmall',
  SMALL = 'is-small',
  MEDIUM = 'is-medium',
  LARGE = 'is-large',
  XLARGE = 'is-xlarge',
}

interface Prop {
  size?: SIZES;
  variation?: APPEARANCES;
}

interface Variation {
  [key: string]: string;
}

const AppLoaderProps = {
  SIZES,
  VARIATIONS
};

export {
  AppLoaderProps,
  SIZES,
  // type Prop, // TODO :: review after vite migration [AMFJ-46]
  //type Variation, // TODO :: // TODO :: review after vite migration [AMFJ-46]
  Prop,
  Variation,
};
