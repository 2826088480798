import { reactive, type Ref } from "vue";
import type { AssetType, Payload, State, DeleteBulkPayload } from "./types";
import auth from "@/store/auth";
import type { AxiosResponse } from "axios";
import {
  deleteAssetType,
  deleteBulkAssetTypes,
  getAssetType,
  getAssetTypes,
  postAssetTypes,
  updateAssetType,
} from "@/api/assettypes.api";
import type { AssetField } from "../assetfields/types";

// state
const state: State = reactive({
  asset_types: [],
  currentAssetType: {
    id: "",
    name: "",
    fields: [],
    field_order: [],
  },
  tableColumns: [
    { key: "name", label: "Name", sortable: true },
    // { key: "asset_count", label: "Number of Assets", sortable: true },
    { key: "update", label: "", sortable: false },
  ],
  didCreateFirstAssetType: false,
});

// methods
const methods = {
  async list(isBlocking = false): Promise<void> {
    await getAssetTypes(isBlocking).then(
      (response: AxiosResponse<AssetType[]>) => {
        state.asset_types = response.data;
      }
    );
  },
  sortFormFields(
    formFields: Array<string>,
    sortedFields: AssetField[]
  ): Array<string> {
    const updatedFields: Array<string> = [];
    sortedFields.forEach((f) => {
      if (formFields.includes(f.id)) {
        updatedFields.push(f.id);
      }
    });

    return updatedFields;
  },
  create(formData: Ref): void {
    const sortedFields: Array<string> = state.currentAssetType.field_order;
    const payload: Payload = this.getCreatePayload(formData, sortedFields);

    if (state.asset_types.length === 0) {
      state.didCreateFirstAssetType = true;
    }
    postAssetTypes(payload).then(() => {
      this.reset();
      this.list();
    });
  },
  createNewAssetType(formData: Ref, formFields: Array<string>): Promise<any> {
    const payload: Payload = this.getCreatePayload(formData, formFields);
    if (state.asset_types.length === 0) {
      state.didCreateFirstAssetType = true;
    }
    return postAssetTypes(payload);
  },
  updateOnGetStarted(
    id: string,
    formData: Ref,
    formFields: Array<string>
  ): void {
    const payload: Payload = this.getCreatePayload(formData, formFields);
    updateAssetType(id, payload).then(() => {
      this.reset();
      this.list();
    });
  },
  update(id: string, formData: Ref): void {
    const sortedFields: Array<string> = state.currentAssetType.field_order;
    const payload: Payload = this.getCreatePayload(formData, sortedFields);

    updateAssetType(id, payload).then(() => {
      this.reset();
      this.list();
    });
  },
  getCreatePayload(formData: Ref, formFields: Array<string>): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      fields: formFields,
      updated_by: auth.state.atlassianUsername,
    };
    return payload;
  },
  get(id: string): void {
    getAssetType(id).then((response: AxiosResponse<AssetType>) => {
      state.currentAssetType = response.data;
    });
  },
  setCurrentAssetType(id: string): void {
    state.asset_types.forEach((at: AssetType) => {
      if (at.id === id) {
        state.currentAssetType = at;
      }
    });
  },
  delete(id: string): void {
    deleteAssetType(id).then(() => {
      state.asset_types = this.removeDeletedAssetTypes([id]);
      this.reset();
    });
  },
  getAssetFields(): Array<string> {
    const fieldIds: Array<string> = [];
    state.currentAssetType.fields.forEach((f) => {
      fieldIds.push(f.id);
    });
    return fieldIds;
  },
  reset(): void {
    state.currentAssetType = {
      id: "",
      name: "",
      fields: [],
      field_order: [],
    };
  },
  updateDidCreateFirstAssetType(): void {
    state.didCreateFirstAssetType = false;
  },
  updateFieldOrder(
    formFields: Array<string>,
    updatedFields: AssetField[]
  ): void {
    const sortedFields: Array<string> = this.sortFormFields(
      formFields,
      updatedFields
    );
    state.currentAssetType.field_order = sortedFields;
  },
  setFieldOrder(fieldIds: Array<string>): void {
    state.currentAssetType.field_order = fieldIds;
  },
  selectAssetFields(
    selectedFieldId: string,
    allFields: Array<AssetField>,
    selectedFields: Array<AssetField>
  ): void {
    const selectedFieldIds: Array<string> = [];

    allFields.forEach((f) => {
      if (f.id === selectedFieldId) {
        selectedFields.push(f);
      }
    });

    selectedFields.forEach((field) => {
      selectedFieldIds.push(field.id);
    });

    state.currentAssetType.fields = selectedFields;
    state.currentAssetType.field_order = selectedFieldIds;
  },
  appendField(newField: AssetField): void {
    state.currentAssetType.fields.push(newField);
    state.currentAssetType.field_order.push(newField.id);
  },
  updateField(updatedField: AssetField): void {
    let fieldIndex = -1;

    state.currentAssetType.fields.forEach((f, i) => {
      if (f.id === updatedField.id) {
        fieldIndex = i;
      }
    });

    if (fieldIndex !== -1) {
      state.currentAssetType.fields[fieldIndex] = updatedField;
    }
  },
  removeDeletedAssetTypes(deletedAssetTypeIds: Array<string>) {
    const remainingAssetTypes: AssetType[] = [];
    state.asset_types.forEach((item) => {
      if (deletedAssetTypeIds.includes(item.id) === false) {
        remainingAssetTypes.push(item);
      }
    });

    return remainingAssetTypes;
  },
  async deleteBulk(ids: Array<string>): Promise<void> {
    const payload: DeleteBulkPayload = {
      asset_type_ids: JSON.stringify(ids),
    };
    await deleteBulkAssetTypes(payload).then(() => {
      state.asset_types = this.removeDeletedAssetTypes(ids);
      this.reset();
    });
  },
};

export default {
  state,
  methods,
};
