import { fetcher } from '#common';
import type { ApiKeysResponse } from '../types';

export const APIKeysApi = {
  createApiKey: () => {
    return fetcher.post<ApiKeysResponse>('apikeys');
  },
  deleteApiKey: (id: number | string) => {
    return fetcher.delete(`apikeys/${id}`);
  },
  getApiKeys: () => {
    return fetcher.get<ApiKeysResponse>('apikeys');
  },
};
