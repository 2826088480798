import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/locations',
    name: RouteName.HOME,
    component: () => import('./views/HomeView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/locations/new',
    name: RouteName.NEW,
    component: () => import('./views/CreateView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/locations/:id/edit',
    name: RouteName.EDIT,
    component: () => import('./views/EditView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
    props: true,
  },
];
