<script setup lang="ts">
import Flag from '@atlaskit/flag';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  title: ComponentProps<typeof Flag>['title'];
  id: ComponentProps<typeof Flag>['id'];
  appearance?: ComponentProps<typeof Flag>['appearance'];
  description?: ComponentProps<typeof Flag>['description'];
  actions?: ComponentProps<typeof Flag>['actions'];
}>();

const AtlasFlag = applyPureReactInVue(Flag);
</script>

<template>
  <AtlasFlag
    :id="id"
    :title="title"
    :appearance="appearance"
    :actions="actions"
  >
    <template #node:icon>
      <slot />
    </template>
    <template #node:description>
      <slot name="description">
        {{ description }}
      </slot>
    </template>
  </AtlasFlag>
</template>
