import type { DeleteBulkPayload, Payload } from "@/store/assettypes/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/asset-types";

// List
const getAssetTypes = (isBlocking: boolean): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking } };
  return apiClient.get(END_POINT, config);
};

// Create Asset Types
const postAssetTypes = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Asset Type
const updateAssetType = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Get Asset Type
const getAssetType = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(`${END_POINT}/${id}`, config);
};

// Delete Asset Type
const deleteAssetType = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

// Delete Bulk Asset Type
const deleteBulkAssetTypes = (
  payload: DeleteBulkPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/delete`, payload);
};

export {
  getAssetTypes,
  postAssetTypes,
  updateAssetType,
  getAssetType,
  deleteAssetType,
  deleteBulkAssetTypes,
};
