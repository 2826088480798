import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from './routes';
import atlaskitStyles from '@atlaskit/css-reset?raw';

const router = createRouter({
  history: createWebHistory('/'),
  routes: [...ROUTES],
});

// @ts-expect-error This comes from the Atlassian connect library
if (typeof AP !== 'undefined' && AP && typeof AP.getLocation === 'function') {
  // We use getLocation to find out if the URL was modified
  // @ts-expect-error This comes from the Atlassian connect library
  AP.getLocation((hostLocation: string) => {
    const currentPath = hostLocation.split('#!');
    if (currentPath.length > 1) {
      const pairs = window.location.search.substring(1).split('&');
      const obj = {};
      let pair, i;

      for (i in pairs) {
        if (pairs[i] === '') continue;

        pair = pairs[i].split('=');
        // @ts-expect-error desc
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }

      // push query params along with the path
      router.push({ path: currentPath[1], query: obj });
    }
  });

  // Update Atlassian's URL history to ensure we can go back and forth
  router.beforeEach((to, from, next) => {
    // @ts-expect-error This comes from the Atlassian connect library
    AP.history.pushState(to.path);
    next();
  });
}

router.beforeResolve((to) => {
  const ATLASKIT_STYLES_ID = 'atlaskit-styles';
  const hasAtlaskitStyles = document.getElementById(ATLASKIT_STYLES_ID);
  if (to.meta.atlaskit) {
    if (hasAtlaskitStyles) return;

    const style = document.createElement('style');
    style.id = ATLASKIT_STYLES_ID;
    style.innerHTML = atlaskitStyles;
    document.head.appendChild(style);
  } else {
    if (!hasAtlaskitStyles) return;

    hasAtlaskitStyles.remove();
  }
});

// Force reload the page when we try to reload a non-existent chunk. This should
// only happen after an update, if the previous chunk was cached by the users
// browser.
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.assign(to.fullPath);
  }
});

export default router;
