export enum TEXT_COLORS {
  CUSTOM = '',
  BLUE_100 = 'text-blue-100',
  BLUE_200 = 'text-blue-200',
  BLUE_300 = 'text-blue-300',
  BLUE_400 = 'text-blue-400',
  BLUE_500 = 'text-blue-500',
  BLUE_600 = 'text-blue-600',
  BLUE_700 = 'text-blue-700',
  BLUE_800 = 'text-blue-800',
  BLUE_900 = 'text-blue-900',
  BLUE_1000 = 'text-blue-1000',
  GREEN_100 = 'text-green-100',
  GREEN_200 = 'text-green-200',
  GREEN_300 = 'text-green-300',
  GREEN_400 = 'text-green-400',
  GREEN_500 = 'text-green-500',
  GREEN_600 = 'text-green-600',
  GREEN_700 = 'text-green-700',
  GREEN_800 = 'text-green-800',
  GREEN_900 = 'text-green-900',
  GREEN_1000 = 'text-green-1000',
  LIME_100 = 'text-lime-100',
  LIME_200 = 'text-lime-200',
  LIME_300 = 'text-lime-300',
  LIME_400 = 'text-lime-400',
  LIME_500 = 'text-lime-500',
  LIME_600 = 'text-lime-600',
  LIME_700 = 'text-lime-700',
  LIME_800 = 'text-lime-800',
  LIME_900 = 'text-lime-900',
  LIME_1000 = 'text-lime-1000',
  MAGENTA_100 = 'text-magenta-100',
  MAGENTA_200 = 'text-magenta-200',
  MAGENTA_300 = 'text-magenta-300',
  MAGENTA_400 = 'text-magenta-400',
  MAGENTA_500 = 'text-magenta-500',
  MAGENTA_600 = 'text-magenta-600',
  MAGENTA_700 = 'text-magenta-700',
  MAGENTA_800 = 'text-magenta-800',
  MAGENTA_900 = 'text-magenta-900',
  MAGENTA_1000 = 'text-magenta-1000',
  ORANGE_100 = 'text-orange-100',
  ORANGE_200 = 'text-orange-200',
  ORANGE_300 = 'text-orange-300',
  ORANGE_400 = 'text-orange-400',
  ORANGE_500 = 'text-orange-500',
  ORANGE_600 = 'text-orange-600',
  ORANGE_700 = 'text-orange-700',
  ORANGE_800 = 'text-orange-800',
  ORANGE_900 = 'text-orange-900',
  ORANGE_1000 = 'text-orange-1000',
  PURPLE_100 = 'text-purple-100',
  PURPLE_200 = 'text-purple-200',
  PURPLE_300 = 'text-purple-300',
  PURPLE_400 = 'text-purple-400',
  PURPLE_500 = 'text-purple-500',
  PURPLE_600 = 'text-purple-600',
  PURPLE_700 = 'text-purple-700',
  PURPLE_800 = 'text-purple-800',
  PURPLE_900 = 'text-purple-900',
  PURPLE_1000 = 'text-purple-1000',
  RED_100 = 'text-red-100',
  RED_200 = 'text-red-200',
  RED_300 = 'text-red-300',
  RED_400 = 'text-red-400',
  RED_500 = 'text-red-500',
  RED_600 = 'text-red-600',
  RED_700 = 'text-red-700',
  RED_800 = 'text-red-800',
  RED_900 = 'text-red-900',
  RED_1000 = 'text-red-1000',
  SLATE_0 = 'text-slate-0',
  SLATE_100 = 'text-slate-100',
  SLATE_200 = 'text-slate-200',
  SLATE_300 = 'text-slate-300',
  SLATE_400 = 'text-slate-400',
  SLATE_500 = 'text-slate-500',
  SLATE_600 = 'text-slate-600',
  SLATE_700 = 'text-slate-700',
  SLATE_800 = 'text-slate-800',
  SLATE_900 = 'text-slate-900',
  SLATE_1000 = 'text-slate-1000',
  SLATE_1100 = 'text-slate-1100',
  TEAL_100 = 'text-teal-100',
  TEAL_200 = 'text-teal-200',
  TEAL_300 = 'text-teal-300',
  TEAL_400 = 'text-teal-400',
  TEAL_500 = 'text-teal-500',
  TEAL_600 = 'text-teal-600',
  TEAL_700 = 'text-teal-700',
  TEAL_800 = 'text-teal-800',
  TEAL_900 = 'text-teal-900',
  TEAL_1000 = 'text-teal-1000',
  YELLOW_100 = 'text-yellow-100',
  YELLOW_200 = 'text-yellow-200',
  YELLOW_300 = 'text-yellow-300',
  YELLOW_400 = 'text-yellow-400',
  YELLOW_500 = 'text-yellow-500',
  YELLOW_600 = 'text-yellow-600',
  YELLOW_700 = 'text-yellow-700',
  YELLOW_800 = 'text-yellow-800',
  YELLOW_900 = 'text-yellow-900',
  YELLOW_1000 = 'text-yellow-1000'
}
