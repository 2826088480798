import {
  checkinAccessory,
  checkoutAccessory,
  createComment,
  updateComment,
  deleteComment,
  deleteAccessory,
  deleteAccessoryAttachments,
  getAccessories,
  getAccessory,
  getAccessoryAttachments,
  getAccessoryComments,
  getAccessoryHistory,
  postAccessories,
  updateAccessory,
} from "@/api/accessories.api";
import { reactive, type Ref } from "vue";
import type {
  Accessory,
  AttachmentPayload,
  CheckinPayload,
  Checkout,
  CheckoutPayload,
  Comment,
  CommentPayload,
  LinkedIssue,
  ListParams,
  Payload,
  State,
} from "./types";

import utils from "@/utils";
import auth from "@/store/auth";
import { type AxiosResponse } from "axios";
import { getLinkedIssues } from "@/api/assets.api";
import { type User } from "../users/types";

// state
const state: State = reactive({
  accessories: [],
  currentAccessory: {
    id: "",
    name: "",
    total_quantity: "",
    location: null,
    checkouts: [],
    order_number: "",
    purchase_cost: "",
    vendor: "",
    purchase_date: null,
    assets: [],
  },
  tableColumns: [
    { key: "name", label: "Name", sortable: true },
    { key: "total_quantity", label: "Total Quantity", sortable: true },
    { key: "available", label: "Available", sortable: false },
    { key: "location", label: "Location", sortable: false },
  ],
  loaneeFilter: "",
  accessoryComments: [],
  accessoryHistory: [],
  linkedIssues: [],
  accessoryAttachments: {
    bucket: "",
    attachments: [],
  },
  accessoryFound: true,
});

// methods
const methods = {
  list(): void {
    const listParams: ListParams = {};

    if (state.loaneeFilter !== "") {
      listParams.user = state.loaneeFilter;
    }

    getAccessories(listParams).then((response: AxiosResponse<Accessory[]>) => {
      this.reset();
      state.accessories = response.data;
    });
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    postAccessories(payload).then(() => {
      this.reset();
      this.list();
    });
  },
  async update(id: string, formData: Ref): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    await updateAccessory(id, payload).then(
      (response: AxiosResponse<Accessory>) => {
        state.currentAccessory = response.data;
      }
    );
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      total_quantity: formData.value.total_quantity.value,
      vendor: formData.value.vendor.value,
      purchase_cost: formData.value.purchase_cost.value,
      order_number: formData.value.order_number.value,
      updated_by: auth.state.atlassianUsername,
    };
    if (formData.value.assets) {
      payload.assets = formData.value.assets.value;
    }
    if (formData.value.location.value != "") {
      payload.location_id = formData.value.location.value;
    }

    if (formData.value.purchase_date.value !== "") {
      payload.purchase_date = utils.renderToDtString(
        formData.value.purchase_date.value
      );
    }

    return payload;
  },
  get(id: string): void {
    getAccessory(id)
      .then((response: AxiosResponse<Accessory>) => {
        state.currentAccessory = response.data;
        this.format();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          state.accessoryFound = false;
        }
      });
  },
  format(): void {
    state.currentAccessory.purchase_date = utils.renderToDate(
      state.currentAccessory.purchase_date
        ? state.currentAccessory.purchase_date + "Z"
        : null
    );
  },
  numAvailableQuantity(accessory: Accessory): number {
    const checkedOutAssets = accessory.checkouts.filter(
      (ch: Checkout) => ch.checkout_status === "loaned_out"
    );
    return parseInt(accessory.total_quantity) - checkedOutAssets.length;
  },
  getComments(id: string): void {
    getAccessoryComments(id).then((response: AxiosResponse<Comment[]>) => {
      state.accessoryComments = response.data;
    });
  },
  getHistory(id: string): void {
    getAccessoryHistory(id).then((response: AxiosResponse) => {
      state.accessoryHistory = response.data;
    });
  },
  delete(id: string): void {
    deleteAccessory(id).then(() => {
      this.reset();
    });
  },
  checkout(id: string, payload: CheckoutPayload): void {
    checkoutAccessory(id, payload).then(() => {
      this.get(state.currentAccessory.id);
    });
  },
  addComment(id: string, payload: CommentPayload): void {
    createComment(id, payload).then((response: AxiosResponse<Comment>) => {
      state.accessoryComments.push(response.data);
    });
  },
  editComment(accessory_id: number, id: number, payload: CommentPayload): void {
    updateComment(accessory_id, id, payload).then(
      (response: AxiosResponse<Comment>) => {
        state.accessoryComments = state.accessoryComments.map((comment) => {
          if (comment.id === id) {
            return response.data;
          }
          return comment;
        });
      }
    );
  },
  removeComment(accessory_id: number, id: number): void {
    deleteComment(accessory_id, id).then(() => {
      state.accessoryComments = state.accessoryComments.filter(
        (comment) => comment.id !== id
      );
    });
  },
  checkin(checkoutId: string): void {
    const payload: CheckinPayload = {
      updated_by: auth.state.atlassianUsername,
    };

    checkinAccessory(checkoutId, payload).then(() => {
      this.get(state.currentAccessory.id);
    });
  },
  getIssues(accessoryId: string): void {
    accessoryId = "ACC-" + accessoryId;
    getLinkedIssues(accessoryId).then(
      (response: AxiosResponse<LinkedIssue[]>) => {
        state.linkedIssues = response.data;
      }
    );
  },
  async updateLoaneeFilter(loaneeSelected: User): Promise<void> {
    state.loaneeFilter = loaneeSelected.id;
    if (state.loaneeFilter) {
      await this.list();
    }
  },
  reset(): void {
    state.currentAccessory = {
      id: "",
      name: "",
      total_quantity: "",
      location: "",
      checkouts: [],
      order_number: "",
      purchase_cost: "",
      vendor: "",
      purchase_date: null,
      assets: [],
    };
    state.accessoryFound = true;
  },
  getAttachments(id: string): void {
    getAccessoryAttachments(id).then(
      (response: AxiosResponse<AttachmentPayload>) => {
        state.accessoryAttachments = response.data;
      }
    );
  },
  async deleteAttachment(
    attachmentId: string,
    accessoryId: string
  ): Promise<void> {
    await deleteAccessoryAttachments(attachmentId, accessoryId).then();
  },
};

export default {
  state,
  methods,
};
