import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/onboarding',
    component: () => import('./layouts/OnboardingLayout.vue'),
    children: [
      {
        path: '',
        name: RouteName.HOME,
        component: () => import('./views/OnboardingHomeView'),
        meta: { atlaskit: true },
      },
      {
        path: 'sync',
        name: RouteName.SYNC,
        component: () => import('./views/SyncAssetsView'),
        meta: { atlaskit: true },
      },
    ],
  },
];
