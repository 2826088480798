import { fetcher } from '@/modules/common';
import type { RequestTypesResponse, ServiceDesksResponse } from '../types/atlassian';
import { type CustomerPortalRequest, type CustomerPortalResponse } from '../types/portal';

export const portalApi = {
  getRequestTypes: async () => {
    if (!window.AP) {
      throw new Error('Atlassian Connect is not available');
    }

    const data = await window.AP.request({
      url: '/rest/servicedeskapi/requesttype',
      experimental: true,
    });

    return JSON.parse(data.body) as RequestTypesResponse;
  },
  getServiceDesks: async () => {
    if (!window.AP) {
      throw new Error('Atlassian Connect is not available');
    }

    const data = await window.AP.request({
      url: '/rest/servicedeskapi/servicedesk',
    });
    return JSON.parse(data.body) as ServiceDesksResponse;
  },
  getPortalSettings: () => {
    return fetcher.get<CustomerPortalResponse>('portal-settings');
  },
  updatePortalSettings: (data: CustomerPortalRequest, id: number) => {
    return fetcher.put<CustomerPortalResponse>(`portal-settings/${id}`, data);
  },
  createPortalSettings: (data: CustomerPortalRequest) => {
    return fetcher.post<CustomerPortalResponse>('portal-settings', data);
  },
};
