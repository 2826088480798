import { ref } from 'vue';

const jwtToken = ref('');

export const useJWT = () => {
  const setToken = (token: string) => {
    jwtToken.value = token;
  };

  const getToken = () => {
    const token = jwtToken.value;

    return `JWT ${token}`;
  };

  const removeToken = () => {
    jwtToken.value = '';
  };

  return { setToken, getToken, removeToken };
};
