import { fetcher } from '#common';
import type { EventsResponse, EventRequest } from '../types';

export const eventsApi = {
  getEvents: () => {
    return fetcher.get<EventsResponse>('events');
  },
  createEvent: (event: EventRequest) => {
    return fetcher.post<EventsResponse>('events', event);
  },
  updateEvent: (event: EventRequest, id: number | string) => {
    return fetcher.put<EventsResponse>(`events/${id}`, event);
  },
};
