import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';

interface Flag {
  id: string;
  title: string;
  description?: string;
  autoDismiss?: boolean;
  type?: 'success' | 'error';
  error?: Error;
  actions?: { content: string; onClick: () => void }[];
}

export const useFlagsStore = defineStore('flags', () => {
  const flags = ref<Flag[]>([]);

  const hasFlag = (id: string) => flags.value.some((flag) => flag.id === id);

  const addFlag = async (flag: Flag) => {
    if (hasFlag(flag.id)) {
      dismissFlag(flag.id);
      await nextTick();
    }

    flag.autoDismiss = flag.autoDismiss ?? true;

    if (flag.type === 'error') {
      flag.actions = [...(flag.actions ?? []), { content: 'Close', onClick: () => dismissFlag(flag.id) }];
    }

    flags.value.unshift(flag);
  };

  const dismissFlag = (id: string) => {
    flags.value = flags.value.filter((flag) => flag.id !== id);
  };

  const dismissFlags = (ids: string[]) => {
    flags.value = flags.value.filter((flag) => !ids.includes(flag.id));
  };

  return {
    flags,
    addFlag,
    dismissFlag,
    dismissFlags,
  };
});
