<template>
  <AppText
    class="mb-4"
    :color="$AppTextProps.COLORS.SLATE_900"
    :size="$AppTextProps.SIZES.H600_KEY_FEATURE_HEADING"
    :tag="$AppTextProps.TAGS.H1"
  >
    How does this work?
  </AppText>

  <AppText
    :color="$AppTextProps.COLORS.SLATE_600"
    :size="$AppTextProps.SIZES.H500_SUB_SECTION_HEADING"
    :tag="$AppTextProps.TAGS.H2"
  >
    Download the template for the correct CSV format. Ensure it includes
    <b class="highlight">Asset Name</b> and <b class="highlight">Asset type</b> columns. Use
    <b class="highlight">YYYY-MM-DD</b> format for date fields.
  </AppText>
</template>

<style scoped>
.highlight {
  @apply font-sans font-semibold text-slate-800;
}
</style>
