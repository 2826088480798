<template>
  <div class="text-center mx-auto my-20">
    <div class="flex justify-center w-72 h-72 mx-auto">
      <img src="/assets/lock-closed.png" />
    </div>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
      You don't have access to Asset Management for Jira
    </h3>
    <p class="mt-1 w-96 text-sm text-gray-500">
      Please contact your Jira admin for additional help.
    </p>
  </div>
</template>
