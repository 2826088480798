import {
  getJiraProjects,
} from "@/api/jira.api";
import type { State } from "./types";

import { reactive } from "vue";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  projects: [],
});

// methods
const methods = {
  getProjects(): void {
    getJiraProjects().then((response: AxiosResponse) => {
      state.projects = response.data;
    });
  },
};

export default {
  state,
  methods,
};
