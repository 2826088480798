export enum RouteName {
  HOME = 'locations-home',
  NEW = 'locations-new',
  EDIT = 'locations-edit',
}

export enum QueryKey {
  LOCATIONS = 'locations',
  LOCATION = 'location',
}

export const LOCATION_NAME_MAX_LENGTH = 255;
