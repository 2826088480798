import { ref } from 'vue';
import posthog from './instance';
import type { FeatureFlag } from './constants';

const hasLoaded = ref(false);

export const loadFeatureFlags = () => {
  return new Promise<void>((resolve) => {
    if (hasLoaded.value) {
      return resolve();
    }

    posthog.onFeatureFlags(() => {
      hasLoaded.value = true;
      resolve();
    });
  });
};

export const canUse = (featureFlag: FeatureFlag) => {
  return posthog.getFeatureFlag(featureFlag);
};
