import { ref } from 'vue';

const isCreateFieldActive = ref(false);

export default function useCreateFieldState() {
  function getCreateFieldState() {
    return isCreateFieldActive.value;
  }

  function updateCreateField(state: boolean) {
    isCreateFieldActive.value = state;
  }

  return {
    getCreateFieldState,
    updateCreateField,
  }
}