import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';
import { APPEARANCES as VARIATIONS } from '@Types';
import { ICONS } from '../AppIcon/types';

enum ROLES {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

enum SPACINGS {
  DEFAULT = 'default',
  COMPACT = 'compact',
  NONE = 'none',
}

enum TAGS {
  BUTTON = 'button',
  LINK = 'a',
}

enum ATTRS_NAMES {
  TYPE = 'type',
  HREF = 'href',
}

const AppButtonProps = {
  ICONS,
  ROLES,
  SPACINGS,
  TAGS,
  VARIATIONS: {
    DANGER: 'danger',
    DEFAULT: 'default',
    LINK: 'link',
    PRIMARY: 'primary',
    PRIMARY_LIGHT: 'primary-light',
    SUBTLE: 'subtle',
    SUBTLE_LINK: 'subtle-link',
    WARNING: 'warning',
  },
};

interface Emit {
  (e: 'click'): void;
}

interface Prop {
  disabled?: boolean;
  iconLeft?: FunctionalComponent<HTMLAttributes & VNodeProps>;
  iconRight?: FunctionalComponent<HTMLAttributes & VNodeProps>;
  label?: string;
  loading?: boolean;
  role?: ROLES;
  selected?: boolean;
  spacing?: SPACINGS;
  tag?: TAGS;
  url?: string;
  variation?: VARIATIONS;
  wide?: boolean;
}

interface DynamicAttr {
  name: ATTRS_NAMES;
  value?: ROLES | string;
}

export {
  AppButtonProps,
  ATTRS_NAMES,
  ROLES,
  SPACINGS,
  VARIATIONS,
  TAGS,
  // TODO :: review after vite migration [AMFJ-46]
  type Prop,
  type Emit,
  type DynamicAttr,
};
