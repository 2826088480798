import type { Payload } from "@/store/events/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/events";

// List
const getEvents = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(END_POINT, config);
};

// Create Event
const createEvents = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}`, payload);
};

// Update Event
const updateEvents = (id: string, payload: Payload): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

export { createEvents, getEvents, updateEvents };
