import { fetcher } from '#common';
import type { KandkjSettingsResponse, IntuneSettingsResponse, KandjiRequest } from '../types';

export const integrationsApi = {
  getIntuneSettings: () => {
    return fetcher.get<IntuneSettingsResponse>('intune-settings');
  },
  disconnectIntune: (id: number | string) => {
    return fetcher.delete(`intune-settings/${id}`);
  },
  getKandjiSettings: () => {
    return fetcher.get<KandkjSettingsResponse>('kandji-settings');
  },
  createKandjiSettings: (settings: KandjiRequest) => {
    return fetcher.post<KandkjSettingsResponse>('kandji-settings', settings);
  },
  updateKandjiSettings: (settings: KandjiRequest, id: number | string) => {
    return fetcher.put<KandkjSettingsResponse>(`kandji-settings/${id}`, settings);
  },
};
