export enum RouteName {
  HOME = 'users-home',
  EDIT = 'users-edit',
  CREATE = 'users-create',
  DETAILS = 'users-details',
}

export enum QueryKey {
  USERS = 'users',
  USER = 'user',
}

export const LOANEE_NAME_MAX_LENGTH = 256;
