<script setup lang="ts">
import { FlagGroup } from '@atlaskit/flag';
import { applyPureReactInVue } from 'veaury';

const emit = defineEmits<{
  dismissed: [string];
}>();

const onDismissed = (id: string) => {
  emit('dismissed', id);
};

const AtlasFlagGroup = applyPureReactInVue(FlagGroup);
</script>

<template>
  <AtlasFlagGroup @dismissed="onDismissed">
    <slot />
  </AtlasFlagGroup>
</template>
