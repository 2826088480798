import type { Payload } from "@/store/assetfields/types";
import type { AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/asset-fields";

// List
const getAssetFields = (): Promise<AxiosResponse> => {
  return apiClient.get(END_POINT);
};

// Create Asset Field
const postAssetFields = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Asset Field
const updateAssetField = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Get Asset Field
const getAssetField = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}`);
};

// Delete Asset Field
const deleteAssetField = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

export {
  getAssetFields,
  postAssetFields,
  updateAssetField,
  getAssetField,
  deleteAssetField,
};
