enum APPEARANCES {
  DANGER = 'danger',
  DEFAULT = 'default',
  LINK = 'link',
  PRIMARY = 'primary',
  PRIMARY_LIGHT = 'primary-light',
  SUBTLE = 'subtle',
  SUBTLE_LINK = 'subtle-link',
  WARNING = 'warning',
}

export { APPEARANCES };
