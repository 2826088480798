import { DATE_PATTERNS } from '@Types';

const dateFormats = [
  {
    format: DATE_PATTERNS['YYYY-MM-DD'],
    pattern: /^(\d{4})-(\d{2})-(\d{2})$/, // YYYY-MM-DD
    constructor: (parts: number[]) => new Date(parts[0], parts[1] - 1, parts[2]),
  },
  {
    format: DATE_PATTERNS['ISO-8601'],
    pattern: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/, // ISO 8601 with time
    constructor: (parts: number[]) =>
      new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]),
  },
  {
    format: DATE_PATTERNS['MM/DD/YYYY'],
    pattern: /^(\d{2})\/(\d{2})\/(\d{4})$/, // MM/DD/YYYY
    constructor: (parts: number[]) => new Date(parts[2], parts[0] - 1, parts[1]),
  },
  {
    format: DATE_PATTERNS['MM.DD.YYYY'],
    pattern: /^(\d{2})\.(\d{2})\.(\d{4})$/, // MM.DD.YYYY
    constructor: (parts: number[]) => new Date(parts[2], parts[0] - 1, parts[1]),
  },
  {
    format: DATE_PATTERNS['MM-DD-YYYY'],
    pattern: /^(\d{2})-(\d{2})-(\d{4})$/, // MM-DD-YYYY
    constructor: (parts: number[]) => new Date(parts[2], parts[0] - 1, parts[1]),
  },
  {
    format: DATE_PATTERNS['YYYY/MM/DD'],
    pattern: /^(\d{4})\/(\d{2})\/(\d{2})$/, // YYYY/MM/DD
    constructor: (parts: number[]) => new Date(parts[0], parts[1] - 1, parts[2]),
  },
  {
    format: DATE_PATTERNS.YYYYMMDD,
    pattern: /^(\d{4})(\d{2})(\d{2})$/, // YYYYMMDD
    constructor: (parts: number[]) => new Date(parts[0], parts[1] - 1, parts[2]),
  },
];

export { dateFormats };