import { reactive, type Ref } from 'vue';
import type { IntuneSettings, Payload, State } from './types';

import { getIntuneSettings, updateIntuneSetting, deleteIntuneSetting } from '@/api/intune.api';
import type { AxiosResponse } from 'axios';

// state
const state: State = reactive({
  intuneSettings: {
    id: '',
    ownership_types: [],
    default_asset_type_id: '',
    default_location_id: '',
    auto_assign_to_user: false,
    intune_fields_mapping: {},
    auto_delete_deactivated_assets: false,
    last_sync: null,
    sync_report: {},
  },
});

// methods
const methods = {
  async list(): Promise<void> {
    await getIntuneSettings().then((response: AxiosResponse<IntuneSettings>) => {
      state.intuneSettings = response.data;
    });
  },
  getCreatePayload(formData: Ref, intuneFieldsMapping: Ref): Payload {
    const intuneFieldsPayload: any = {};
    Object.keys(intuneFieldsMapping.value).forEach((key) => {
      intuneFieldsMapping.value[key].forEach((field: any) => {
        if (field.value) {
          intuneFieldsPayload[field.id] = field.value.value;
        }
      });
    });

    const payload: Payload = {
      ownership_types: formData.value.managedDeviceOwnerTypes.value,
      default_asset_type_id: formData.value.defaultAssetType.value,
      default_location_id: formData.value.defaultLocation.value,
      auto_assign_to_user: formData.value.autoAssignAssets.value,
      intune_fields_mapping: JSON.stringify(intuneFieldsPayload),
      auto_delete_deactivated_assets: formData.value.autoDeleteDevices.value,
    };
    return payload;
  },
  update(id: string, formData: Ref, intuneFieldsMapping: Ref): void {
    const payload: Payload = this.getCreatePayload(formData, intuneFieldsMapping);
    updateIntuneSetting(id, payload).then(() => {
      this.list();
    });
  },
  disconnect(id: string): void {
    deleteIntuneSetting(id).then(() => {
      this.reset();
    });
  },
  reset(): void {
    state.intuneSettings = {
      id: '',
      ownership_types: [],
      default_asset_type_id: '',
      default_location_id: '',
      auto_assign_to_user: false,
      intune_fields_mapping: {},
      auto_delete_deactivated_assets: false,
      last_sync: null,
      sync_report: {},
    };
  },
};

export default {
  state,
  methods,
};
