import {
  checkinLicense,
  checkoutLicense,
  createComment,
  updateComment,
  deleteComment,
  deleteLicense,
  deleteLicenseAttachments,
  getLicenses,
  getLicense,
  getLicenseAttachments,
  getLicenseComments,
  getLicenseHistory,
  postLicenses,
  updateLicense,
} from "@/api/licenses.api";
import { reactive, type Ref } from "vue";
import type {
  License,
  AttachmentPayload,
  CheckinPayload,
  Checkout,
  CheckoutPayload,
  Comment,
  CommentPayload,
  LinkedIssue,
  ListParams,
  Payload,
  State,
} from "./types";

import utils from "@/utils";
import auth from "@/store/auth";
import type { AxiosResponse } from "axios";
import { getLinkedIssues } from "@/api/assets.api";
import type { User } from "../users/types";

// state
const state: State = reactive({
  licenses: [],
  currentLicense: {
    id: "",
    name: "",
    total_seats: "",
    min_seats: null,
    email: "",
    order_number: "",
    purchase_date: null,
    renewal_date: null,
    purchase_cost: "",
    vendor: "",
    checkouts: [],
    assets: [],
  },
  tableColumns: [
    { key: "name", label: "Name", sortable: true },
    { key: "total_seats", label: "Total Seats", sortable: true },
    { key: "available", label: "Available", sortable: true },
  ],
  loaneeFilter: "",
  licenseComments: [],
  licenseHistory: [],
  linkedIssues: [],
  licenseAttachments: {
    bucket: "",
    attachments: [],
  },
});

// methods
const methods = {
  list(): void {
    const listParams: ListParams = {};

    if (state.loaneeFilter !== "") {
      listParams.user = state.loaneeFilter;
    }

    getLicenses(listParams).then((response: AxiosResponse<License[]>) => {
      this.reset();
      state.licenses = response.data;
    });
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    postLicenses(payload).then(() => {
      this.reset();
      this.list();
    });
  },
  async update(id: string, formData: Ref): Promise<void> {
    const payload: Payload = this.getCreatePayload(formData);
    await updateLicense(id, payload).then(
      (response: AxiosResponse<License>) => {
        state.currentLicense = response.data;
      }
    );
  },
  getCreatePayload(formData: Ref): Payload {
    const min_seats = formData.value.min_seats.value
      ? formData.value.min_seats.value
      : null;
    const payload: Payload = {
      name: formData.value.name.value,
      total_seats: formData.value.total_seats.value,
      min_seats: min_seats,
      email: formData.value.email.value,
      vendor: formData.value.vendor.value,
      purchase_cost: formData.value.purchase_cost.value,
      renewal_date: formData.value.renewal_date.value,
      order_number: formData.value.order_number.value,
      updated_by: auth.state.atlassianUsername,
    };
    if (formData.value.assets) {
      payload.assets = formData.value.assets.value;
    }

    if (formData.value.purchase_date.value !== "") {
      payload.purchase_date = utils.renderToDtString(
        formData.value.purchase_date.value
      );
    }

    if (formData.value.renewal_date.value !== "") {
      payload.renewal_date = utils.renderToDtString(
        formData.value.renewal_date.value
      );
    }

    return payload;
  },
  get(id: string): void {
    getLicense(id).then((response: AxiosResponse<License>) => {
      state.currentLicense = response.data;
    });
  },
  numAvailableQuantity(license: License): number {
    const checkedOutAssets = license.checkouts.filter(
      (ch: Checkout) => ch.checkout_status === "loaned_out"
    );
    return parseInt(license.total_seats) - checkedOutAssets.length;
  },
  getComments(id: string): void {
    getLicenseComments(id).then((response: AxiosResponse<Comment[]>) => {
      state.licenseComments = response.data;
    });
  },
  getHistory(id: string): void {
    getLicenseHistory(id).then((response: AxiosResponse) => {
      state.licenseHistory = response.data;
    });
  },
  delete(id: string): void {
    deleteLicense(id).then(() => {
      this.reset();
    });
  },
  checkout(id: string, payload: CheckoutPayload): void {
    checkoutLicense(id, payload).then(() => {
      this.get(state.currentLicense.id);
    });
  },
  addComment(id: string, payload: CommentPayload): void {
    createComment(id, payload).then((response: AxiosResponse<Comment>) => {
      state.licenseComments.push(response.data);
    });
  },
  editComment(license_id: number, id: number, payload: CommentPayload): void {
    updateComment(license_id, id, payload).then(
      (response: AxiosResponse<Comment>) => {
        state.licenseComments = state.licenseComments.map((comment) => {
          if (comment.id === id) {
            return response.data;
          }
          return comment;
        });
      }
    );
  },
  removeComment(license_id: number, id: number): void {
    deleteComment(license_id, id).then(() => {
      state.licenseComments = state.licenseComments.filter(
        (comment) => comment.id !== id
      );
    });
  },
  checkin(checkoutId: string): void {
    const payload: CheckinPayload = {
      updated_by: auth.state.atlassianUsername,
    };

    checkinLicense(checkoutId, payload).then(() => {
      this.get(state.currentLicense.id);
    });
  },
  getIssues(licenseId: string): void {
    licenseId = "LIC-" + licenseId;
    getLinkedIssues(licenseId).then(
      (response: AxiosResponse<LinkedIssue[]>) => {
        state.linkedIssues = response.data;
      }
    );
  },
  async updateLoaneeFilter(loaneeSelected: User): Promise<void> {
    state.loaneeFilter = loaneeSelected.id;
    if (state.loaneeFilter) {
      await this.list();
    }
  },
  reset(): void {
    state.currentLicense = {
      id: "",
      name: "",
      total_seats: "",
      min_seats: null,
      email: "",
      checkouts: [],
      order_number: "",
      purchase_cost: "",
      renewal_date: null,
      vendor: "",
      purchase_date: null,
      assets: [],
    };
  },
  getAttachments(id: string): void {
    getLicenseAttachments(id).then(
      (response: AxiosResponse<AttachmentPayload>) => {
        state.licenseAttachments = response.data;
      }
    );
  },
  async deleteAttachment(
    attachmentId: string,
    licenseId: string
  ): Promise<void> {
    await deleteLicenseAttachments(attachmentId, licenseId).then();
  },
};

export default {
  state,
  methods,
};
