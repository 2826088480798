import { ref } from 'vue';
import type { AtlassianMeta } from '../types/meta';

const metadata = ref<AtlassianMeta>({
  atlassianUsername: '',
  atlassianId: '',
});

export const useMeta = () => {
  const setMeta = (meta: AtlassianMeta) => {
    metadata.value = meta;
  };

  const getMeta = () => {
    return metadata.value;
  };

  const removeMeta = () => {
    metadata.value = {
      atlassianUsername: '',
      atlassianId: '',
    };
  };

  return { setMeta, getMeta, removeMeta };
};
