import { reactive, type Ref } from "vue";
import type { Alert, Payload, State } from "./types";

import auth from "@/store/auth";
import {
  deleteAlert,
  getAlert,
  getAlerts,
  postAlerts,
  updateAlert,
} from "@/api/alerts.api.";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  alerts: [],
  currentAlert: {
    id: "",
    name: "",
    filter_conditions: [],
    email_recipients: [],
    enabled: false,
  },
  tableColumns: [
    { key: "field", label: "Name", sortable: true },
    { key: "status", label: "Status", sortable: false },
    { key: "update", label: "", sortable: false },
  ],
});

// methods
const methods = {
  list(): void {
    getAlerts().then((response: AxiosResponse<Alert[]>) => {
      state.alerts = response.data;
    });
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    postAlerts(payload).then(() => {
      this.reset();
      this.list();
    });
  },
  update(id: string, formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    updateAlert(id, payload).then(() => {
      this.reset();
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const payload: Payload = {
      name: formData.value.name.value,
      filter_conditions: JSON.stringify(formData.value.filterConditions.value),
      email_recipients: formData.value.emails.value.toString(),
      updated_by: auth.state.atlassianUsername,
      enabled:
        formData.value.enabled === undefined
          ? true
          : formData.value.enabled.value,
    };
    return payload;
  },
  get(id: string): void {
    getAlert(id).then((response: AxiosResponse<Alert>) => {
      state.currentAlert = response.data;
    });
  },
  setCurrentAlert(id: string): void {
    state.alerts.forEach((alert: Alert) => {
      if (alert.id === id) {
        state.currentAlert = alert;
      }
    });
  },
  delete(id: string): void {
    deleteAlert(id).then(() => {
      this.reset();
    });
  },
  reset(): void {
    state.currentAlert = {
      id: "",
      name: "",
      filter_conditions: [],
      email_recipients: [],
      enabled: false,
    };
  },
  toggleAlertStatus(alert: Alert): void {
    const payload: Payload = {
      name: alert.name,
      email_recipients: alert.email_recipients.toString(),
      filter_conditions: JSON.stringify(alert.filter_conditions),
      enabled: alert.enabled,
    };
    updateAlert(alert.id, payload).then(() => {
      this.reset();
      this.list();
    });
  },
};

export default {
  state,
  methods,
};
