import type {
  CheckinPayload,
  CheckoutPayload,
  CommentPayload,
  ListParams,
  Payload,
} from "@/store/accessories/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/accessories";

// List Accessories
const getAccessories = (params: ListParams): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: params,
    headers: { isBlocking: true },
  };
  return apiClient.get(END_POINT, config);
};

// Create Accessory
const postAccessories = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Get Accessory
const getAccessory = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(`${END_POINT}/${id}`, config);
};

const getAccessoryComments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/comments`);
};

const createComment = (
  id: string,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${id}/comments`, payload);
};

const updateComment = (
  accessory_id: number,
  id: number,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${accessory_id}/comments/${id}`, payload);
};

const deleteComment = (
  accessory_id: number,
  id: number
): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${accessory_id}/comments/${id}`);
};

const getAccessoryHistory = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/history`);
};

const deleteAccessory = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

const updateAccessory = (
  id: string,
  payload: Payload
): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

const checkoutAccessory = (
  id: string,
  payload: CheckoutPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${id}/checkout`, payload);
};

const checkinAccessory = (
  checkoutId: string,
  payload: CheckinPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${checkoutId}/checkin`, payload);
};

// accessory attachments
const getAccessoryAttachments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/attachments`);
};

const deleteAccessoryAttachments = (
  id: string,
  accessoryId: string
): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${accessoryId}/attachments/${id}`);
};

export {
  getAccessories,
  postAccessories,
  getAccessory,
  getAccessoryComments,
  getAccessoryHistory,
  deleteAccessory,
  updateAccessory,
  checkoutAccessory,
  createComment,
  updateComment,
  deleteComment,
  checkinAccessory,
  getAccessoryAttachments,
  deleteAccessoryAttachments,
};
