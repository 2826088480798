import { isValidDate } from '@Helpers';
import { AppIconProps } from '@/components/app/AppIcon/types';
import { OPTIONS_TYPES } from '@BaseUi';
import { ASSET_TYPES } from './types';

const ICONS = generateICONS();

function generateICONS(): Record<ASSET_TYPES | string, string> {
  const iconMappings: Record<ASSET_TYPES, string | any> = {
    [ASSET_TYPES.NUMBER]: 'number.svg',
    [ASSET_TYPES.DATE]: AppIconProps.ICONS.CalendarIcon_,
    [ASSET_TYPES.STRING]: 'text.svg',
    [ASSET_TYPES.DROPDOWN]: 'dropdown.svg',
  };

  const iconStrings: Record<string, string | any> = {
    number: 'number.svg',
    date: AppIconProps.ICONS.CalendarIcon_,
    string: 'text.svg',
    dropdown: 'dropdown.svg',
  };

  return { ...iconMappings, ...iconStrings };
}

function getAssetType(asset: string): ASSET_TYPES {
  /*
   * NOTE - Improvement candidate
   * At this moment, we are not validating each entry field type.
   * Because of that we can't have the dropdown type automatically set after parsing.
   * There is room to do that though. Instead of checking only the first entry, we
   * could check all the fields to validate its type. In that same loop, we could
   * find patterns using [...new Set(arr)] and create a dropdown asset type.
   */

  if (/^\d+(\.\d+)?$/.test(asset)) return ASSET_TYPES.NUMBER;

  if (isValidDate(asset)) return ASSET_TYPES.DATE;

  return ASSET_TYPES.STRING;
}

function getAssetTypeIcon(type: ASSET_TYPES | string): string {
  return ICONS[type] || 'text.svg';
}

function mapFieldType(apiFieldType: string): OPTIONS_TYPES | undefined {
  switch (apiFieldType) {
    case 'text':
      return OPTIONS_TYPES.TEXT;
    case 'number':
      return OPTIONS_TYPES.NUMBER;
    case 'date':
      return OPTIONS_TYPES.DATE;
    case 'dropdown':
      return OPTIONS_TYPES.DROPDOWN;
    default:
      return undefined;
  }
}

export { getAssetType, getAssetTypeIcon, mapFieldType };
