import { useQuery } from '@tanstack/vue-query';
import { ADMIN_GROUPS, QueryKey } from '../constants';
import { api } from '../api';
import { DEFAULT_STALE_TIME_IN_MS } from '@/modules/common';
import { computed, watch } from 'vue';
import { useSettings } from '#settings/composables';
import { useMeta } from './useMeta';

export const useAuth = () => {
  const { setMeta } = useMeta();
  const { query: settingsQuery } = useSettings();

  const query = useQuery({
    queryKey: [QueryKey.AUTH],
    queryFn: () => api.getAuth(),
    staleTime: DEFAULT_STALE_TIME_IN_MS,
  });

  const isCurrentUserSiteAdmin = computed(() => {
    if (!query.data.value) return false;

    return query.data.value.groups.items.some((group) => ADMIN_GROUPS.includes(group.name));
  });

  const atlassianUsername = computed(() => {
    if (!query.data.value) return '';

    return query.data.value.displayName;
  });

  watch(query.data, (authData) => {
    if (!authData) return;

    setMeta({
      atlassianUsername: authData.displayName,
      atlassianId: authData.accountId,
    });
  });

  const hasFullAccess = computed(() => {
    if (isCurrentUserSiteAdmin.value) return true;

    const settings = settingsQuery.data.value;
    if (!settings) return false;

    const onlyAdminsAllowed = settings.should_allow_admins_only;
    if (!onlyAdminsAllowed) return true;

    const groupsWithAccess = settings.groups_allowed_to_access || [];
    const userGroups = query.data.value?.groups.items || [];

    return userGroups.some((group) => groupsWithAccess.includes(group.groupId));
  });

  return { query, isCurrentUserSiteAdmin, atlassianUsername, hasFullAccess };
};
