export default [
  {
    path: '/assets/import-new', // TODO : [AMFJ-53] This route will be updated for the final release
    name: 'assets-import',
    component: () => import('@/modules/Asset/Import/views/AssetImportOnboardView.vue'),
    beforeEnter: () => {
      document.querySelector('body')?.classList.add('asset-module');
    },
  },
  {
    path: '/assets/import/results',
    name: 'assets-import-results',
    component: () => import('@/modules/Asset/Import/views/AssetImportResultsView.vue'),
    beforeEnter: () => {
      document.querySelector('body')?.classList.add('asset-module-results');
    },
  },
  {
    path: '/assets/import/status-new', // TODO : [AMFJ-53] This route will be updated for the final release
    name: 'assets-import-status-new',
    component: () => import('@/modules/Asset/Import/views/AssetImportStatusView.vue'),
  },
];
