import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/audit-logs',
    name: RouteName.HOME,
    component: () => import('./views/HomeView'),
    meta: { atlaskit: true, appWrapper: true },
  },
];
